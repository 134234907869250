import { createContext, useState, useEffect } from "react";
import config from "../configs/config";

const AuthContext = createContext({
  isLoggedIn: false,
  user: "",
  userId: "",
  admin: false,
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {

  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const LogOut = async () => {
    fetch(config.BACKEND_URL+"auth/signout", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
    })
      .then((response) => {
        console.log(response, 'respuesta de logout');
        return response;
      })
      .catch((err) => {
        console.log(err, 'error en logout');
        return err;
      });
  }

  const loginHandler = (user, admin, email) => {
    setUser(user);
    setAdmin(admin);
    setLoggedIn(true);
    console.log(user);
    console.log(admin);
    console.log("correctly logged in");
  };

  //Check if user is logged
  const CheckLogIn = async () => {
    console.log(config.BACKEND_URL)
    await fetch(config.BACKEND_URL+"auth/signed", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
    })
      .then((response) => {
        console.log(response, 'respuesta desde check signed');
        if (response.ok){
          setLoggedIn(true);
          return response.json();
        }
        else{
          return false
        }
        
      }).then(data => {
        // console.log('data payload from login', data.payload.email);
        loginHandler(data.payload.firstName, data.payload.sudo)
        setUserId(data.payload.id);
        return true
      } )
      .catch((err) => {
        console.log(err, 'error en login');
        return false;
      });
  };

  const logoutHandler = () => {
    setUser(null);
    setUserId(null);
    setAdmin(false);
    setLoggedIn(false);
    LogOut()
    console.log("correctly logged off");
  };

  const contextValue = {
    user: user,
    admin: admin,
    userId: userId,
    isLoggedIn: loggedIn,
    login: loginHandler,
    logout: logoutHandler,
    CheckLogIn : CheckLogIn,
    isLoading: isLoading,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
