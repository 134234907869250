const Health = (props) => {

  return (
    <>
        <h3>The App is Healthy</h3>
    </>
  );
};

export default Health;
