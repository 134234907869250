//Components
import { Card } from "antd";
import CardTitle from "../../components/UI/CardTitle";
import { FaUserEdit } from "react-icons/fa";
import EditUserForm from "../../components/Forms/EditUserForm";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const EditarUsuario = () => {
    
  const { t } = useTranslation();
  let { id } = useParams()
  
  return (
      <Card
        title={<CardTitle title={t("edit-user")} icon={FaUserEdit} />}
        style={{ margin: "1rem" }}
      >
        <EditUserForm userId={id} />
      </Card>
    )
}

export default EditarUsuario;