//React utilities
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Form, Col, Input, Button, Checkbox, Select, Dropdown, DatePicker, Menu, Modal, Upload, message } from "antd";
import { FaIdCard } from "react-icons/fa";
import config from "../../configs/config";
import OlFormatKML from 'ol/format/KML';
import OlFormatWKT from 'ol/format/WKT';
import MultiPolygon from 'ol/geom/MultiPolygon.js';
import Feature from 'ol/Feature.js';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function removeZDimensionFromWKT(wkt) {
  const format = new OlFormatWKT();
  const feature = format.readFeature(wkt);
  
  const geometry = feature.getGeometry();
  const coordinates = geometry.getCoordinates();
  
  const newCoordinates = coordinates.map((polygon) =>
    polygon.map((ring) => ring.map((point) => point.slice(0, 2)))
  );
  
  geometry.setCoordinates(newCoordinates);
  
  return format.writeFeature(new Feature(geometry));
}

const CreateRegionForm = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [nInputs, setNInputs] = useState(1);
  const [nselected, setNSelected] = useState(0);
  const hiddenFileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileWKT, setSelectedFileWKT] = useState("");
  const [isPublic, setIsPublic] = useState(false)
  const [statics, setStatics] = useState(false)
  const [form] = Form.useForm();

  const handleCheckboxPublic = e => {
    setIsPublic(e.target.checked);
  };

  const handleCheckboxStatics = e => {
    setStatics(e.target.checked);
  };


  const addInput_onClick = () => {
    setNInputs((current) => current + 1);
  };
  const removeInput_onClick = () => {
    if (nInputs > 0) {
      setNInputs((current) => current - 1);
    }
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const handleChange = async (event) => {

    const kmlFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(kmlFile);
    reader.onload = function (event) {
      
      const kmlFormat = new OlFormatKML();
      const kmlFeatures = kmlFormat.readFeatures(event.target.result);
      const featureGeometries = kmlFeatures.map(feature => feature.getGeometry());
      const multiPolygonGeometry = new MultiPolygon(featureGeometries);
      const wktGeometry = new OlFormatWKT().writeGeometry(multiPolygonGeometry);
      const projection = kmlFormat.readProjection(kmlFeatures);
      const projectionCode = projection.getCode();
      const finalWkt = removeZDimensionFromWKT(wktGeometry)
      const wktWithProjection = `SRID=${projectionCode.slice(5,)};${finalWkt}`;
      setSelectedFileWKT(wktWithProjection)

    };

    setSelectedFile(event.target.files[0].name);
  };

  const onFinish = (values) => {
  
    console.log("Received values of form: ", values);
    // setIsLoading(true);
    console.log('body', JSON.stringify({
      short_name: values.short_name,
      long_name: values.full_name,
      is_public: isPublic,
      calculate_stats: statics,
      location: selectedFileWKT,
    }))
    fetch(config.BACKEND_URL+"maps/region/new-region", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        short_name: values.short_name,
        long_name: values.full_name,
        is_public: isPublic,
        calculate_stats: statics,
        location: selectedFileWKT,
      }),
    })
      .then((response) => {
        if (response.ok) {
          const successModal = Modal.success({
            title: '',
            content: 'Ha creado correctamente la región',
          });
          setTimeout(() => {
            successModal.destroy();
            window.location.reload();
          }, 1800);
          return response.json();
        } else {
          return response.json().then((data) => {
            let errorMessage = "No se ha podido crear la región";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            }
            throw new Error(errorMessage)
          });
        }
      })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        Modal.error({
          title: 'Error',
          content: err.message,
          centered: true,
          onOk: () => {
            // Cerrar modal después de 3 segundos
            setTimeout(() => {
              Modal.destroyAll();
            }, 1000);
          },
        });
      });
    
  };

  
  const handleMenuClick = (e) => {
    return null;
  };

  const handleCheckboxClick = (e) => {
    let nModify = 0;
    e.target.checked ? (nModify = 1) : (nModify = -1);
    setNSelected((prev) => prev + nModify);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          key: "1",
          label: <Checkbox onClick={handleCheckboxClick}>Usuario 1</Checkbox>,
        },
        {
          key: "2",
          label: <Checkbox onClick={handleCheckboxClick}>Usuario 2</Checkbox>,
        },
        {
          key: "3",
          label: <Checkbox onClick={handleCheckboxClick}>Usuario 3</Checkbox>,
        },
      ]}
    />
  );

  return (
    <Form layout={"vertical"} name="basic" form={form} onFinish={onFinish}>
      <Form.Item
        label={t('short-name')}
        name="short_name"
        rules={[
          {
            required: true,
            message: t('error-msg.invalid-shname'),
            min: 3,
          },
        ]}
      >
        <Input addonBefore={<FaIdCard />} />
      </Form.Item>

      <Form.Item
        label={t('full-name')}
        name="full_name"
        rules={[
          {
            required: true,
            message: t('error-msg.invalid-fullname'),
            min: 5,
          },
        ]}
      >
        <Input addonBefore={<FaIdCard />} />
      </Form.Item>
      <Form.Item label={t('vector-file')}  rules={[{ required: true }]}>
        <Input
        disabled
          value={selectedFile}
          addonAfter={
            <Button size="small" type="text" onClick={handleClick}>
              {t('browse')}
            </Button>
          }
        />
        <input
          className="input_shapefile"
          type="file"
          style={{ display: "none" }}
          ref={hiddenFileInput}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: "5px" }}>
        <Checkbox value={false} onChange={handleCheckboxPublic} >{t('public')}</Checkbox>
      </Form.Item>
      <Form.Item >
        <Checkbox value={false} onChange={handleCheckboxStatics} >{t("generate-report")}</Checkbox>
      </Form.Item>

{/*       <Form.Item label="Reciven Informes" >
        <Input.Group compact >
          {Array.from(Array(nInputs).keys()).map((x, index) => {
            return <Input key={index} type="email" />;
          })}
        </Input.Group>
        <Button
          type="secondary"
          onClick={addInput_onClick}
          shape="circle"
          size="small"
          style={{ marginRight: "5px", marginTop: "3px" }}
          icon={<AiOutlinePlus />}
        ></Button>
        {nInputs > 0 && (
          <Button
            onClick={removeInput_onClick}
            type="secondary"
            shape="circle"
            size="small"
            style={{ marginTop: "3px" }}
            icon={<AiOutlineMinus />}
          ></Button>
        )}
      </Form.Item> */}

{/*       <Form.Item label="Fechas">
        <Input.Group compact>
          <Input
            disabled
            style={{ width: "3em", cursor: "default" }}
            prefix={<FaCalendarAlt style={{ color: "rgba(0,0,0,.85)" }} />}
          />
          <DatePicker.RangePicker
            suffixIcon={null}
            style={{ width: "calc(100% - 3em)" }}
          />
        </Input.Group>
      </Form.Item>
      <Form.Item label="Idioma">
        <Input.Group compact>
          <Input
            disabled
            style={{ width: "3em", cursor: "default" }}
            prefix={
              <FaLanguage
                style={{ color: "rgba(0,0,0,.85)", fontSize: "1.5em" }}
              />
            }
          />
          <Select style={{ width: "calc(100% - 3em)" }}>
            <Option value="En">Inglés</Option>
            <Option value="Es">Español</Option>
            <Option value="Pr">Portugués</Option>
          </Select>
        </Input.Group>
      </Form.Item> */}

{/*       <Form.Item label={"Propietarios"}>
        <Dropdown
          overlay={menu}
          onVisibleChange={handleVisibleChange}
          visible={visible}
          placement="topLeft"
          trigger={"click"}
        >
          <Button type="primary" style={{ width: "100%" }}>
            {nselected} Seleccionados
          </Button>
        </Dropdown>
      </Form.Item> */}

      <Form.Item>
        <Row gutter={3}>
          <Col>
            <Button
              type="primary"
              danger={true}
              onClick={() => window.location.reload()}
            >
              {t('cancel')}
            </Button>
          </Col>
          {/* <Col>
            <Button
              type="primary"
              onClick={() => alert("'Ver Mapa' no implementado")}
            >
              Ver Mapa
            </Button>
          </Col> */}

          <Col>
            <Button type="primary" htmlType="submit">
            {t('create-region')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default CreateRegionForm;
