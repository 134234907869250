import Usuarios from "./views/admin/Usuarios";
import CrearUsuario from "./views/admin/CrearUsuario";
import Regiones from "./views/admin/Regiones";
import CrearRegion from "./views/admin/CrearRegion";
import EditarRegion from "./views/EditarRegion";
import {
  FaUsers,
  FaListAlt,
  FaChartPie,
  FaAtlas,
  FaUserPlus,
  FaUsersCog,
  FaMapMarkedAlt,
  FaIdBadge,
  FaCogs,
  FaMap,
} from "react-icons/fa";
import { RiMapPinAddFill } from "react-icons/ri";
import Configuracion from "./views/perfil/Configuracion";
import MisRegiones from "./views/perfil/MisRegiones";
import Graficos from "./views/Graficos";
import Informes from "./views/Informes";
import Login from "./views/Login";
import Health from "./views/Health";
import Recovery from "./views/Recovery";
import MapaPublico from "./views/MapaPublico";
import MapaDinamico from "./views/MapaDinamico";
import EditarUsuario from "./views/admin/EditarUsuario";
import EmailValidationPage from "./components/UI/EmailValidator";


const routes = [
  
  {
    name: "Default",
    path: "",
    hide: "true",
    layout: "dashboard",
    component: <MapaPublico />,
    icon: null,
  },
  {
    name: "Validar correo",
    path: "verify-email",
    layout: "dashboard",
    hide: 'false',
    component: <EmailValidationPage />,
    // icon: <FaUserPlus />,
  },
  {
    name: "admin",
    collapse: true,
    path: "admin",
    layout: "dashboard",
    icon: <FaUsersCog />,
    admin:true,
    views: [
      {
        name: "users",
        path: "usuarios",
        layout: "dashboard",
        component: <Usuarios />,
        icon: <FaUsers />,
      },
      {
        name: "create-user",
        path: "crear-usuario",
        layout: "dashboard",
        component: <CrearUsuario />,
        icon: <FaUserPlus />,
      },
      {
        name: "Editar Usuario",
        path: "editar/:id",
        layout: "dashboard",
        hide: 'false',
        component: <EditarUsuario />,
        // icon: <FaUserPlus />,
      },
      // {
      //   name: "Procesos",
      //   path: "procesos",
      //   layout: "dashboard",
      //   component: <Procesos />,
      //   icon: <FaLaptopCode />,
      // },
      // {
      //   name: "Satelites",
      //   path: "satelites",
      //   layout: "dashboard",
      //   component: <Satelites />,
      //   icon: <FaSatellite />,
      // },
      {
        name: "regions",
        path: "regiones",
        layout: "dashboard",
        component: <Regiones />,
        icon: <FaMapMarkedAlt />,
      },
      {
        name: "create-region",
        path: "crear-region",
        layout: "dashboard",
        component: <CrearRegion />,
        icon: <RiMapPinAddFill />,
      },
      {
        name: "Editar region",
        path: "editar-region/:id",
        layout: "dashboard",
        hide: 'true',
        component: <EditarRegion admin={true} />,
        // icon: <FaUserPlus />,
      },
    ],
  },
  {
    name: "my-profile",
    collapse: true,
    path: "perfil",
    layout: "dashboard",
    icon: <FaIdBadge />,
    views: [
      {
        name: "settings",
        path: "configuracion",
        layout: "dashboard",
        component: <Configuracion />,
        icon: <FaCogs />,
      },
      {
        name: "my-regions",
        path: "mis-regiones",
        layout: "dashboard",
        component: <MisRegiones />,
        icon: <FaAtlas />,
      },
      {
        name: "Crear Región",
        hide: true,
        path: "crear-region",
        layout: "dashboard",
        component: <CrearRegion />,
        icon: <RiMapPinAddFill />,
      },
      {
        name: "Editar region",
        path: "editar-region/:id",
        layout: "dashboard",
        hide: 'true',
        component: <EditarRegion admin={false} />,
        // icon: <FaUserPlus />,
      },
    ],
  },
  {
    name: "maps",
    collapse: true,
    path: "mapas",
    layout: "dashboard",
    icon: <FaMap />,
    views: [
      {
        name: "dynamic-map",
        path: "mapa-dinamico",
        layout: "dashboard",
        component: <MapaDinamico />,
        icon: <FaMap />,
      },
      {
        name: "public-map",
        path: "mapa-publico-login",
        layout: "dashboard",
        component: <MapaPublico />,
        icon: <FaMap />,
      },
    ],
  },
  {
    hide: true,
    path: "editar-region",
    layout: "dashboard",
    component: <EditarRegion />,
  },
  {
    name: "charts",
    path: "grafico-emisiones",
    layout: "dashboard",
    component: <Graficos />,
    icon: <FaChartPie />,
  },
  {
    name: "reports",
    path: "informes",
    layout: "dashboard",
    component: <Informes />,
    icon: <FaListAlt />,
  },
  {
    name: "Login",
    path: "login",
    layout: "",
    component: <Login/>,
    icon: null,
  },
  {
    name: "Recovery",
    path: "restaurar-contrasena",
    layout: "",
    component: <Recovery />,
    icon: null,
  },
  {
    name: "Mapa Publico",
    path: "",
    layout: "",
    component: <MapaPublico download={false}/>,
    icon: null,
  },
  {
    name: "Validar correo",
    path: "verify-email/*",
    layout: "",
    component: <EmailValidationPage />,
    icon: <FaUserPlus />,
  },
];


export default routes;
