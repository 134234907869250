import React, { useEffect, useRef, useState, useContext } from "react";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";
import { RMap, ROSM, RControl, RLayerWMS } from "rlayers";
import "./Maps.css";
import {
  FaEllipsisH,
  FaChartBar,
  FaDownload,
  FaPaintRoller,
} from "react-icons/fa";
import { Card } from "antd";
import MapStatistics from "./MapStatistics";
import MapColorSettings from "./MapColorSettings";
import MapDownload from "./MapDownload";
import CSSTransition from "react-transition-group/CSSTransition";
import { RScaleLine } from "rlayers/control";
import config from "../../configs/config";
import AuthContext from "../../store/auth-context";
import GeoserverLayer from "./GeoserverLayer";
import ColorbarLegend from "./ColorbarLegend";

const { RCustom, RFullScreen } = RControl;

const center = fromLonLat([-70.498586, -15.072488]);

const getStatsByDateId = async (setState, region_id, date) => {
  let date_str = "";
  date_str = date_str + date.year().toString() + "-";
  if (date.month() <= 8) {
    date_str = date_str + "0" + (date.month() + 1).toString() + "-";
  } else {
    date_str = date_str + (date.month() + 1).toString() + "-";
  }
  date_str = date_str + "01";
  console.log("region_id", region_id);
  console.log("date_str", date_str);
  await fetch(config.BACKEND_URL + "maps/stats/get-by-id-and-date", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
    },
    credentials: "include",
    body: JSON.stringify({
      initialDate: date_str,
      regions: [region_id],
      endingDate: date_str,
      gas: 1,
    }),
  })
    .then((response) => {
      if (response.ok) {
        // setLoggedIn(true);
        console.log(response, "pruebaok");
        return response.json();
      } else {
        return false;
      }
    })
    .then((data) => {
      // setUsersData(data.payload)
      console.log("data desde getStatsByDateId", data);
      try {
        const stats_fetched = data.payload[0];
        console.log(stats_fetched);
        setState({
          min_value: stats_fetched.min_value,
          max_value: stats_fetched.max_value,
          mean_value: stats_fetched.mean_value,
          std_value: stats_fetched.std_value,
        });
        return true;
      } catch {
        return false;
      }
    })
    .catch((err) => {
      console.log("err", err);
      return false;
    });
};

const getDefaultConfig = async (setState) => {
  await fetch(config.BACKEND_URL + "maps/gas/default/get-config", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
    },
    credentials: "include",
    body: JSON.stringify({
      gas: 1,
    }),
  })
    .then((response) => {
      if (response.ok) {
        // setLoggedIn(true);
        console.log(response, "pruebaok");
        return response.json();
      } else {
        return false;
      }
    })
    .then((data) => {
      // setUsersData(data.payload)
      console.log("data desde map config default", data);
      setState(data.payload);
      return true;
    })
    .catch((err) => {
      console.log("err", err);
      return false;
    });
};

const getUserConfig = async (setState) => {
  try {
    const response = await fetch(config.BACKEND_URL + "maps/gas/get-config", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        gas: 1,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.status === "fail") {
        return getDefaultConfig(setState);
      }
      console.log("data desde map config user", data);
      setState(data.payload);
      return true;
    }
  } catch (err) {
    console.log("err", err);
    return getDefaultConfig(setState);
  }
};

const PublicMap = (props) => {
  const [stats, setStats] = useState({
    min_value: undefined,
    max_value: undefined,
    mean_value: undefined,
    std_value: undefined,
  });
  const regionId = props.regionId;
  const { user } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState("stats");
  const [show, setShow] = useState(false);

  const [colorSettings, setColorSettings] = useState({});

  const onTabChange = (key) => {
    setActiveTab(key);
  };
  const toggleShow = () => {
    setShow((prev_show) => !prev_show);
  };

  useEffect(() => {
    if (user) {
      getUserConfig(setColorSettings);
    } else {
      getDefaultConfig(setColorSettings);
    }
  }, []);

  console.log(colorSettings);

  const contentList = {
    stats: (
      <MapStatistics
        min={stats.min_value}
        max={stats.max_value}
        mean={stats.mean_value}
        dev={stats.std_value}
      />
    ),
    dwnld: <MapDownload />,
    color: (
      <MapColorSettings values={colorSettings} setValues={setColorSettings} />
    ),
  };

  const tabList = [
    {
      key: "stats",
      tab: <FaChartBar />,
    },
    {
      key: "color",
      tab: <FaPaintRoller />,
    },
    {
      key: "dwnld",
      tab: props.download ? <FaDownload /> : null,
    },
  ];
  console.log(colorSettings);

  let date = new Date(1, 1, 1);
  if (props.date) {
    date = new Date(props.date.year(), props.date.month(), 1, 0, 0, 0);
    date = new Date(
      date.getFullYear(),
      date.getMonth(),
      1,
      -date.getUTCHours(),
      0,
      0
    );
  }

  useEffect(() => {
    if (props.date) {
      getStatsByDateId(setStats, regionId, props.date);
    }
  }, [regionId, props.date]);

  return (
    <React.Fragment>
      <RMap
        width={"100%"}
        height={"calc(100vh - 11em)"}
        initial={{ center: center, zoom: 3.5 }}
      >
        <RFullScreen />
        <ROSM />
        <ColorbarLegend
          min={colorSettings["value_min"]}
          max={colorSettings["value_max"]}
          palette={colorSettings["map_style"]}
        />
        <RCustom className={"customControl"}>
          <button onClick={toggleShow}>
            <FaEllipsisH />
          </button>
        </RCustom>
        <GeoserverLayer
          min={colorSettings["value_min"]}
          max={colorSettings["value_max"]}
          palette={colorSettings["map_style"]}
          region={props.region}
          date={date}
        />
        <CSSTransition
          mountOnEnter
          unmountOnExit
          in={show}
          timeout={400}
          classNames="configCardAnimation"
        >
          <Card
            activeTabKey={activeTab}
            tabList={tabList}
            onTabChange={onTabChange}
            className="configCard"
          >
            {contentList[activeTab]}
          </Card>
        </CSSTransition>
        <RScaleLine bar={true} />
      </RMap>
    </React.Fragment>
  );
};

export default PublicMap;
