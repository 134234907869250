//Components
import { Card } from "antd";
import CardTitle from "../../components/UI/CardTitle";
import { FaUsers } from "react-icons/fa";
import UsersTable from "../../components/Tables/UsersTable";
import { useTranslation } from 'react-i18next';



const Usuarios = () => {
    
    const { t } = useTranslation();
    return (
        <Card title={<CardTitle title={t('users')} icon={FaUsers}/>} style={{ margin: "1rem" }}>
            <UsersTable/>
        </Card>
    )
}

export default Usuarios