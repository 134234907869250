import config from "../../configs/config";

export const deleteUser = async (userId) => {
  await fetch(config.BACKEND_URL+"user/delete-user", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
    },
    credentials: "include",
    body: JSON.stringify({
      id: userId
    }),
  })
    .then((response) => {
      
      if (response.ok){
        // setLoggedIn(true);
        console.log(response, 'pruebaok');
        window.location.reload()
        return response.json();
      }
      else{
        return false
      }
      
    })
    .catch((err) => {
      return false;
    });
};

export const resendVerfEmail = async (userId) => {
  await fetch(config.BACKEND_URL+"user/email/make-verfication", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
    },
    credentials: "include",
    body: JSON.stringify({
      id: userId
    }),
  })
    .then((response) => {
      
      if (response.ok){
        // setLoggedIn(true);
        console.log(response, 'pruebaok');
        window.location.reload()
        return response.json();
      }
      else{
        return false
      }
      
    })
    .catch((err) => {
      return false;
    });
};