import React, { useContext, useState } from "react";
import RegionSelector from "../components/RegionSelector/RegionSelector";
import CardTitle from "../components/UI/CardTitle";
import { Card, Col, Row } from "antd";
import { FaListAlt } from "react-icons/fa";
import ReportTable from "../components/Tables/ReportTable";
import { useTranslation } from 'react-i18next';
import AuthContext from "../store/auth-context";

const Informes = () => {
  const { t } = useTranslation();
  const { user, CheckLogIn, admin } = useContext(AuthContext)
  
  const [dateRange, setDateRange] = useState(undefined);
  const [region, setRegion] = useState(undefined);

  return (
    <Card
      style={{ margin: "1rem" }}
      title={<CardTitle title={t("reports")} icon={FaListAlt} />}
    >
      <Row style={{ marginLeft: "1em", marginRight: "1em", marginTop: "1em" }}>
        <Col span={8}>
          <Card>
            <RegionSelector
              mode="single"
              layout="vertical"
              setRange={setDateRange}
              setRegions={setRegion}
              user={user}
              admin={admin}
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card>
            <ReportTable dateRange={dateRange} region={region} />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default Informes;
