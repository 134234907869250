//React utilities
import { useState, useEffect } from "react";
//Hooks & Routing
import { useNavigate } from "react-router-dom";
//Components
import { Row, Form, Col, Input, Button, Checkbox, Modal, Card, Spin } from "antd";
import { FaIdCard } from "react-icons/fa";
import config from "../../configs/config";
import { useTranslation } from 'react-i18next';


const EditRegionForm = ({regionId, admin}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [renderKey, setRenderKey] = useState(0);
  const [isPublic, setIsPublic] = useState(false)
  const [statics, setStatics] = useState(false)
  const [regionsData, setRegionsData] = useState(false);

  const handleCheckboxPublic = e => {
    setIsPublic(e.target.checked);
  };

  const handleCheckboxStatics = e => {
    setStatics(e.target.checked);
  };


  let rol_user_id = 0

  const onFinish = (values) => {
    
    fetch(config.BACKEND_URL+"maps/region/modify-region", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        id: regionId,
        short_name: values.short_name,
        long_name: values.full_name,
        role_id: rol_user_id,
        is_public: isPublic,
        calculate_stats: statics,
        // email: values.email,
        // password: values.password,
      }),
    })
      .then((response) => {
        // setIsLoading(false);
        if (response.ok) {
          const successModal = Modal.success({
            title: '',
            content: t("alert-msg.region-updated")
          });
          setTimeout(() => {
            successModal.destroy();
            window.location.reload();
          }, 1800);
          return response.json();
        } else {
          return response.json().then((data) => {
            let errorMessage = "Error de autentificación";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            }
            throw new Error(errorMessage)
          });
        }
      })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        Modal.error({
          title: 'Error',
          content: err.message,
          centered: true,
          onOk: () => {
            setTimeout(() => {
              Modal.destroyAll();
            }, 1000);
          },
        });
      });
  };

  const getRegion = async (regionId) => {
    await fetch(config.BACKEND_URL+"maps/region/get", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        id: regionId,
        // password: values.password,
      }),
    })
      .then((response) => {
        
        if (response.ok){
          console.log(response, 'pruebaok');
          return response.json();
        }
        else{
          return false
        }
        
      }).then(data => {

        console.log('data desde users', data)
        setRegionsData(data.payload)
        setIsPublic(data.payload.is_public)
        setStatics(data.payload.calculate_stats)
        return true
      } )
      .catch((err) => {
        return false;
      });
  };

  useEffect(() => {
    getRegion(regionId)
  }, [regionId]);

  if (!regionsData) {

    return (
      <Card style={{ margin: "1rem", height: "590px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin size="large" />
      </Card>
    );
  }


  return (
    <Form layout={"vertical"} name="basic" form={form} onFinish={onFinish}>
    <Form.Item
      label={t('short-name')}
      name="short_name"
      initialValue={regionsData.short_name}
      rules={[
        {
          required: true,
          message: t('error-msg.invalid-shname'),
          min: 3,
        },
      ]}
    >
      <Input addonBefore={<FaIdCard />} />
    </Form.Item>

    <Form.Item
      label={t('full-name')}
      name="full_name"
      initialValue={regionsData.long_name}

      rules={[
        {
          required: true,
          message: t('error-msg.invalid-fullname'),
          min: 5,
        },
      ]}
    >
      <Input addonBefore={<FaIdCard />} />
    </Form.Item>
    <Form.Item style={{ marginBottom: "5px" }}>
      <Checkbox checked={isPublic} onChange={handleCheckboxPublic} >{t('public')}</Checkbox>
    </Form.Item>
    <Form.Item >
      <Checkbox checked={statics} onChange={handleCheckboxStatics} >{t('generate-report')}</Checkbox>
    </Form.Item>
      <Form.Item>
        <Row gutter={3}>
          <Col>
            <Button type="danger" onClick={() => window.location.reload()}>
            {t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
            {t('update')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default EditRegionForm;
