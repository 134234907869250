import { Table } from "antd";

const COLUMNS = [
  {
    dataIndex: "stat",
  },
  {
    dataIndex: "value",
  },
];

const createStatsTable = (min, max, mean, dev) => {
  return [
    {
      key: "min",
      stat: "Min",
      value: min,
    },
    {
      key: "max",
      stat: "Max",
      value: max,
    },
    {
      key: "mean",
      stat: "Mean",
      value: mean,
    },
    {
      key: "dev",
      stat: "Dev",
      value: dev,
    },
  ];
};

const MapStatistics = ({ min, max, mean, dev }) => {
  return (
    <Table
      showHeader={false}
      pagination={false}
      columns={COLUMNS}
      dataSource={createStatsTable(min, max, mean, dev)}
    ></Table>
  );
};

export default MapStatistics;
