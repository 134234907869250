import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Table, Button, Typography, Col, Row, Modal } from "antd";
import { FaPlus, FaEye, FaTrash, FaEdit } from "react-icons/fa";
import config from "../../configs/config";
import { useTranslation } from 'react-i18next';

const Text = Typography.Text;


const { confirm } = Modal;

const deleteRegion = async (userId) => {
  await fetch(config.BACKEND_URL+"maps/region/delete-region", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
    },
    credentials: "include",
    body: JSON.stringify({
      id: userId
    }),
  })
    .then((response) => {
      
      if (response.ok){
        // setLoggedIn(true);
        console.log(response, 'pruebaok');
        window.location.reload()
        return response.json();
      }
      else{
        return false
      }
      
    })
    .catch((err) => {
      return false;
    });
};

const RegionsTable = ({admin}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [regionsData, setRegionsData] = useState(false);

  const showDeleteConfirm = (userId) => {
    confirm({
      title: t("alert-msg.delete-region"),
      icon: <ExclamationCircleFilled />,
      content: t("alert-msg.confirm"),
      okText: t("delete"),
      okType: 'danger',
      cancelText: t("cancel"),
      onOk() {
        deleteRegion(userId)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const getRegions = async () => {
    let url = admin ? "/maps/region/get-all-list" : "maps/region/get-only-user-list"
    await fetch(config.BACKEND_URL+url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
    })
      .then((response) => {
        
        if (response.ok){
          // setLoggedIn(true);
          console.log(response, 'pruebaok');
          return response.json();
        }
        else{
          return false
        }
        
      }).then(data => {
        setRegionsData(data.payload)
        console.log('data desde users', data.payload)
        return true
      } )
      .catch((err) => {
        return false;
      });
  };

  useEffect(() => {
    getRegions()
  }, []);




  const COLUMNS = [
    {
      title: <Text strong>{t('short-name')}</Text>,
      dataIndex: "short_name",
      sorter: (a, b) => (a.short_name < b.short_name ? -1 : 1),
    },
    {
      title: <Text strong>{t('full-name')}</Text>,
      dataIndex: "long_name",
      sorter: (a, b) => (a.long_name < b.long_name ? -1 : 1),
    },
    {
      title: <Text strong>{t('privacy')}</Text>,
      dataIndex: "is_public",
      sorter: (a, b) =>
        a.status.props.childen < b.status.props.childen ? -1 : 1,
      render: (value) => 
      <Fragment> 
        { value? <Text type="warning">{t('region-status_pu')}</Text> : <Text type="secondary">{t('region-status_pr')}</Text> }
      </Fragment>,
    },
    {
      title: <Text strong>{t('creation-date')}</Text>,
      dataIndex: "created",
      sorter: (a, b) =>
        Date(a.fecha_creacion) < Date(b.fecha_creacion) ? -1 : 1,
      render: (value) => 
      <Fragment> 
        { new Date(value).getDate() + "-" 
        + (new Date(value).getMonth()+1) + "-"
        + new Date(value).getFullYear() } 
      </Fragment>,
    },
    {
      dataIndex: "actions",
      key: "x",
      render: (_, record) => (
        <>
          <Row gutter={5}>
            <Col>
              <Button
                onClick={() => {
                  console.log(_);
                  console.log(record);
                  { admin ? 
                    navigate(`/dashboard/admin/editar-region/${record.id}`) : 
                    navigate(`/dashboard/perfil/editar-region/${record.id}`) 
                  }
                }}
                type={"primary"}
                icon={<FaEdit />}
              />
            </Col>
            {/* <Col>
              <Button
                onClick={() => {
                  console.log(_);
                  console.log(record);
                  alert("Funcionalidad todavía no implementada")
                }}
                type="primary"
                className="button_watch"
                icon={<FaEye />}
              />
            </Col> */}
            <Col>
              <Button
                onClick={() => {
                  console.log(_);
                  console.log(record);
                  showDeleteConfirm(record.id)
                }}
                danger={true}
                type="primary"
                icon={<FaTrash />}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        columns={COLUMNS}
        scroll={{ x: 800 }}
        dataSource={regionsData} //dataSource={}
        rowKey={(regionsData) => regionsData.id}
        style={{ width: "100%" }}
        pagination={{ position: ["topRight"] }}
      ></Table>
      <Button
        type="primary"
        icon={<FaPlus style={{ marginRight: "3px" }} />}
        className={"button_create"}
        onClick={() => { admin ? 
          navigate("/dashboard/admin/crear-region/") : 
          navigate("/dashboard/perfil/crear-region/") 
        }}
      >
        {t('create-region')}
      </Button>
    </>
  );
};

export default RegionsTable;
