import { Card } from "antd";
import CardTitle from "../../components/UI/CardTitle";
import { FaMapMarkedAlt } from "react-icons/fa";
import RegionsTable from "../../components/Tables/RegionsTable";
import { useTranslation } from 'react-i18next';

const Regiones = () => {
  const { t } = useTranslation();

  return (
    <Card
      title={<CardTitle title={t("regions")} icon={FaMapMarkedAlt} />}
      style={{ margin: "1rem" }}
    >
      <RegionsTable admin={true} />
    </Card>
  );
};

export default Regiones;
