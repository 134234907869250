//Language Support
import { ConfigProvider } from "antd";
import enGB from "antd/lib/locale/en_GB";
import esEs from "antd/lib/locale/es_ES";
//Routing
import { Routes, Route, Outlet } from "react-router-dom";
import routesjs from "./routes";
//Layout
import DashboardLayout from "./layout/DashboardLayout";
//Components
//Style
import "./App.css";
import PublicLayout from "./layout/publicLayout";
import EditarRegion from "./views/EditarRegion";
import EditarUsuario from "./views/admin/EditarUsuario";
import Health from "./views/Health";
import { useContext } from "react";
import { LanguageContext } from "./context/LangContext";


const getRoutes = (routes, layout) => {
  return routes.map((route, index) => {
    if (route.collapse) {
      return (
        <Route path={route.path} key={index} element={<Outlet />}>
          {getRoutes(route.views, layout)}
        </Route>
      );
    } else {
      if (route.layout === layout) {
        return (
          <Route path={route.path} key={index} element={route.component} />
        );
      } else {
        return null;
      }
    }
  });
};

function App() {

  const {language} = useContext(LanguageContext)
  
  return (
    <ConfigProvider locale={language}>
      <Routes>
        <Route
          path={"/dashboard"}
          element={<DashboardLayout routes={routesjs} />}
        >
          {getRoutes(routesjs, "dashboard")}
          <Route path={"editar-region"} element={<EditarRegion />} />
          <Route path={"editar-usuario"} element={<EditarUsuario />} />
        </Route>
        <Route path={"/health"} element={<Health/>}>
        </Route>
        <Route path={"/"} element={<PublicLayout />}>
          {getRoutes(routesjs, "")}
        </Route>

      </Routes>
    </ConfigProvider>
  );
}

export default App;
