import { Layout, Image, Divider } from "antd";
import UserAvatarHeader from "./UserAvatarHeader";
import LanguageSelector from "./LanguageSelector";
import cepal_logo from "../../assets/CEPAL_logo.png";
import "./CustomHeader.css";

const { Header } = Layout;

const DashboardHeader = (props) => {
  
  return (
    <Header className={"header_style"}>
      <div className="logo">
        <a
          href="https://www.cepal.org/es"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={cepal_logo} preview={false} />
        </a>
      </div>
      <div style={{ float: "right" }}>
        <Divider type="vertical" style={{ height: "2em" }} />
        <UserAvatarHeader />
      </div>
      <div style={{ float: "right" }}>
        {/* <Divider type="vertical" style={{ height: "2em" }} /> */}
        <LanguageSelector />
      </div>
    </Header>
  );
};

export default DashboardHeader;
