import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Typography, Row, Col, Modal } from "antd";
import { FaUserEdit, FaTrash, FaPlus } from "react-icons/fa";
import "./CustomTables.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import config from "../../configs/config";
import { useTranslation } from 'react-i18next';
import { TbMailForward } from 'react-icons/tb';
import { deleteUser, resendVerfEmail } from "./fetch_functions";

const { confirm } = Modal;
const Text = Typography.Text;

const UsersTable = (props) => {
  
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [usersData, setUsersData] = useState(false);
  
  const buttonStyle = {
    backgroundColor: 'green', 
    borderColor: 'green', 
  };

  const handleModalAlert = (userId, deleteFlag) => {

    if (deleteFlag === true) {
      confirm({
        title: t("alert-msg.delete-user"),
        icon: <ExclamationCircleFilled />,
        content: t("alert-msg.confirm"),
        okText: t("delete"),
        okType: 'danger',
        cancelText: t("cancel"),
        onOk() {
          deleteUser(userId)
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    } else {
      confirm({
        title: t("alert-msg.resend-ver-email"),
        icon: <ExclamationCircleFilled />,
        content: t("alert-msg.confirm"),
        okText: t("alert-msg.resend"), 
        okType: 'danger',
        cancelText: t("cancel"),
        onOk() {
          resendVerfEmail(userId)
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    }

  };

  const getUsers = async () => {
    await fetch(config.BACKEND_URL+"user/get-all", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
    })
      .then((response) => {
        
        if (response.ok){
          // setLoggedIn(true);
          console.log(response, 'pruebaok');
          return response.json();
        }
        else{
          return false
        }
        
      }).then(data => {
        setUsersData(data.payload)
        console.log('data desde users', usersData)
        return true
      } )
      .catch((err) => {
        return false;
      });
  };

  useEffect(() => {
    getUsers()
  }, []);

  const roles = {
    1: t('public'),
    2: t('admin'),
  };

  const COLUMNS = [
    {
      title: <Text strong>{t('first-name')}</Text>,
      dataIndex: "firstName",
      sorter: (a, b) => (a.nombre < b.nombre ? -1 : 1),
    },
    {
      title: <Text strong>{t('last-name')}</Text>,
      dataIndex: "lastName",
      sorter: (a, b) => (a.apellido < b.apellido ? -1 : 1),
    },
    {
      title: <Text strong>{t('email´')}</Text>,
      dataIndex: "email",
      sorter: (a, b) => (a.email < b.email ? -1 : 1),
    },
    {
      title: <Text strong>{t('type')}</Text>,
      dataIndex: "role_id",
      sorter: (a, b) => (a.tipo < b.tipo ? -1 : 1),
      render: (value) => <Fragment>{roles[value]}</Fragment>,
    },
    {
      title: <Text strong>{t('status')}</Text>,
      dataIndex: "verified",
      sorter: (a, b) =>
        a.status.props.childen < b.status.props.childen ? -1 : 1,
      render: (value) => 
      <Fragment> 
        { value? <Text type="success">{t('active')}</Text> : <Text type="danger">{t('inactive')}</Text> }
      </Fragment>,
    },
    {
      title: <Text strong>{t('creation-date')}</Text>,
      dataIndex: "createdAt",
      sorter: (a, b) =>
        Date(a.fecha_creacion) < Date(b.fecha_creacion) ? -1 : 1,
      render: (value) => 
      <Fragment> 
        { new Date(value).getDate() + "-" 
        + (new Date(value).getMonth()+1) + "-"
        + new Date(value).getFullYear() } 
      </Fragment>,
    },
    {
      dataIndex: "actions",
      key: "x",
      render: (_, record) => (
        <Fragment>
          <Row gutter={5}>
            <Col>
              <Button
                onClick={() => {
                  console.log(_);
                  console.log(record);
                  navigate(`/dashboard/admin/editar/${record.id}`)
                }}
                type={"primary"}
                icon={<FaUserEdit />}
              />
            </Col>
            <Col>
              <Button
                onClick={() => {
                  console.log(_);
                  console.log(record);
                  handleModalAlert(record.id, true)
                }}
                danger={true}
                type={"primary"}
                icon={<FaTrash/>}
              />
            </Col>
            <Col>
              <Button
                onClick={() => {
                  console.log(record.id);
                  handleModalAlert(record.id, false)
                }}
                type={"primary"}
                icon={<TbMailForward/>}
                style={buttonStyle}
              />
            </Col>
          </Row>
        </Fragment>
      ),
    },
  ];
  return (
    <Fragment>
      <Table
        columns={COLUMNS}
        scroll={{ x: 800 }}
        dataSource={usersData} //dataSource={}
        rowKey={(usersData) => usersData.id}
        pagination={{ position: ["topRight"] }}
      ></Table>
      <Button type="primary" icon={<FaPlus style={{marginRight:"5px"}}/>} className="button_create" onClick={() => navigate('/dashboard/admin/crear-usuario/')}>
      {t('create-user')}
      </Button>
    </Fragment>
  )
};

export default UsersTable;
