import React from "react";
import classes from "./ColorbarLegend.module.css";
import "ol/ol.css";
import { RMap, ROSM, RControl, RLayerTileWMS, RContext } from "rlayers";
import { Row, Col } from "antd";
import chroma from "chroma-js";
import "./colorbar.css";
const { RCustom, RFullScreen } = RControl;

const ColorbarLegend = (props) => {
    const ColorSchemes = {
        calido: ["#F80000", "#E06200", "#01F099", "#17DB02", "#FAF802", "#053ADC"],
        frio: ["#632459", "#A33B92", "#5D5179", "#4F759B", "#92C9B1", "#A2FAA3"],
        magma: ["#1B1044", "#4A1079", "#7E2481", "#B0347B", "#E85461", "#FC9366"],
        plasma: ["#18068B", "#6500A7", "#A92395", "#E3675F", "#FBA337", "#F2F126"],
        viridis: ["#450558", "#424085", "#2C718E", "#24AA82", "#77D052", "#F1E51C"],
        default: ["#F80000", "#E06200", "#01F099", "#17DB02", "#FAF802", "#053ADC"],
    };
    const arrayToRGBString = (rgbColor) => {
        return (
            "rgb(" +
            rgbColor[0].toString() +
            "," +
            rgbColor[1].toString() +
            "," +
            rgbColor[2].toString() +
            "," +
            rgbColor[3].toString() +
            ")"
        );
    };
    const palette = props.palette?props.palette:"calido";
    const scale = chroma.scale(ColorSchemes[palette]).domain([0, 10]).mode("lrgb"); //pallete
    const scaleArray = Array.from(Array(10).keys());

    console.log(palette);
    return (
        <RCustom className={classes.legend_container}>
            <div
                style={{
                    position: "absolute",
                    top: "0px",
                    left: "1em",
                    fontFamily: "sans-serif",
                }}
            >
                {props.max}KgC/m^2
            </div>
            <div className={classes.legend}>
                <Col style={{ textAlign: "-webkit-center" }}>
                    {scaleArray.map((cel) => {
                        return (
                            <div
                                key={cel}
                                className={classes.cell}
                                style={{
                                    background: `linear-gradient(${arrayToRGBString(scale(cel)._rgb)} 20%, ${arrayToRGBString(
                                        scale(cel + 1)._rgb
                                    )} 100%)`,
                                    //background:arrayToRGBString(scale(cel)._rgb)
                                }}
                            />
                        );
                    })}
                </Col>
            </div>
            <div
                style={{
                    position: "absolute",
                    bottom: "0em",
                    left: "1em",
                    fontFamily: "sans-serif",
                }}
            >
                {" "}
                {props.min}KgC/m^2
            </div>
        </RCustom>
    );
};

export default ColorbarLegend;

ColorbarLegend.defaultProps = {
    min: "0.000",
    max: "0.201",
    style: "calido",
};
