import { Table, Select, Button, InputNumber } from "antd";
import { useState, useContext, useEffect } from "react";
import config from "../../configs/config";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";

const COLUMNS = [
  {
    dataIndex: "stat",
  },
  {
    dataIndex: "value",
  },
];

const setUserConfig = async (min, max, color) => {
  await fetch(config.BACKEND_URL + "/maps/gas/set-config", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
    },
    credentials: "include",
    body: JSON.stringify({
      value_min: min,
      value_max: max,
      gas: 1,
      range_style: color,
      map_style: color,
    }),
  })
    .then((response) => {
      if (response.ok) {
        // setLoggedIn(true);
        console.log(response, "pruebaok");
        return response.json();
      } else {
        return false;
      }
    })
    .then((data) => {
      // setUsersData(data.payload)
      console.log("data desde set map config", data);
      return true;
    })
    .catch((err) => {
      console.log("err", err);
      return false;
    });
};

const MapColorSettings = (props) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  console.log("props.values", props.values, user);
  const [min, setMin] = useState(props.values.value_min);
  const [max, setMax] = useState(props.values.value_max);
  const [color, setColor] = useState(props.values.map_style);

  const handleMinChange = (value) => {
    // Validar que el valor mínimo no sea mayor que el valor máximo
    if (value > max) {
      return;
    }
    changeMin(value);
  };

  const handleMaxChange = (value) => {
    // Validar que el valor máximo no sea menor que el valor mínimo
    if (value < min) {
      return;
    }
    changeMax(value);
  };

  const selectChildren = [
    {
      value: "calido",
      label: t("warm"),
    },
    {
      value: "frio",
      label: t("cold"),
    },
    {
      value: "magma",
      label: "Magma",
    },
    {
      value: "plasma",
      label: "Plasma",
    },
    {
      value: "viridis",
      label: "Viridis",
    },
  ];

  const changeMin = (e) => {
    setMin(e);
  };
  const changeMax = (e) => {
    setMax(e);
  };

  const changeType = (e) => {
    const newColor = e;
    setColor(newColor);
    console.log("color", newColor);
  };


  const onUpdateHandler = () => {
    if (user) {
      setUserConfig(min, max, color);
    }
    console.log(color)
    props.setValues({
      "value_min": min,
      "value_max": max,
      "map_style": color
    })
  };

  const createStatsTable = () => {
    if (color === "default") {
      setColor("calido");
    }
    return [
      {
        key: "min",
        stat: "Min",
        value: (
          <InputNumber
            max={1999}
            defaultValue={min}
            step={0.01}
            onChange={handleMinChange}
            formatter={(value) => `${value}`}
            parser={(value) => value}
          />
        ),
      },
      {
        key: "max",
        stat: "Max",
        value: (
          <InputNumber
            max={2000}
            defaultValue={max}
            step={0.01}
            onChange={handleMaxChange}
            formatter={(value) => `${value}`}
            parser={(value) => value}
          />
        ),
      },
      {
        key: "type",
        stat: t("type"),
        value: (
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            defaultValue={color}
            onChange={changeType}
            options={selectChildren}
          />
        ),
      },
      {
        key: "dev",
        stat: "",
        value: (
          <Button type="primary" onClick={onUpdateHandler}>
            {t("update")}
          </Button>
        ),
      },
    ];
  };
  return (
    <Table
      showHeader={false}
      pagination={false}
      columns={COLUMNS}
      dataSource={createStatsTable()}
      size="middle"
    ></Table>
  );
};

export default MapColorSettings;

MapColorSettings.defaultProps = {
  setScheme: (e) => null,
  setBoundaries: (min, max) => null,
};
