//Components
import { Layout, Image, Divider } from "antd";
import UserAvatarHeader from "./UserAvatarHeader";
import LanguageSelector from "./LanguageSelector";
//Images
import cepal_logo from "../../assets/CEPAL_logo.png";
import "./CustomHeader.css"

const { Header } = Layout;

const CustomHeader = (props) => {
  return (
    <Header className={"header_style"}>
      <div className="logo">
        <a
          href="https://www.cepal.org/es"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={cepal_logo} preview={false} />
        </a>
      </div>
      {!props.noUser && <div style={{ float: "right" }}>
        <Divider type="vertical" style={{ height: "2em" }} />
        <UserAvatarHeader />
      </div>}
      {
        props.noUser && <div style={{ float: "right" }}>
        <Divider type="vertical" style={{ height: "2em" }} />
        <a href="login" style={{color:"black"}}>Iniciar Sesion</a>
      </div>
      }
      <div style={{ float: "right" }}>
        <Divider type="vertical" style={{ height: "2em" }} />
        <LanguageSelector />
      </div>
    </Header>
  );
};

export default CustomHeader;
