import React, { useEffect, useState } from 'react';
import { Result, Button, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import config from '../../configs/config';

const EmailValidationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [tokenValidated, setTokenValidated] = useState(false);

  const fetchBackendWithToken = (token) => {
    fetch(config.BACKEND_URL+'user/email/verify', {
      method: 'POST',
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({ token: token }),
    })
    .then((response) => {
      if (response.ok) {
        console.log('verified');
        setTokenValidated(true);
        return response.json();
      } else {
        setTokenValidated(false);
      }
    })
    .catch((err) => {
      console.log(err);
      setTokenValidated(false);
    });
  };

  useEffect(() => {
    const token = queryString.parse(location.search).token;
    if (token && !tokenValidated) {
      fetchBackendWithToken(token);
      console.log('token', token);
    }
  }, [location.search, tokenValidated]);

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      navigate('/');
    }, 30000);

    return () => clearTimeout(redirectTimer);
  }, [navigate]);

  useEffect(() => {
    if (validated) {
      setTokenValidated(true);
    }
  }, [validated]);

  useEffect(() => {
    if (tokenValidated) {
      setValidated(true);
    }
  }, [tokenValidated]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '85vh',
      }}
    >
      {tokenValidated ? (
        <Result
          icon={<CheckCircleOutlined />}
          title={t("alert-msg.user-validated")}
          extra={
            <Button type="primary" onClick={() => navigate('/')}>
              {t("continue")}
            </Button>
          }
        />
      ) : (
        <Spin size="large" />
      )}
    </div>
  );
};
export default EmailValidationPage;
