import { useState, useContext } from "react";
import DynamicMap from "../components/Maps/DynamicMap";
import RegionSelector from "../components/RegionSelector/RegionSelector";
import AuthContext from "../store/auth-context";

const MapaDinamico = (props) => {
  const [region, setRegion] = useState("worldmap");
  const [regionId, setRegionId] = useState(undefined)
  const [dateRange, setDateRange] = useState(undefined);
  const { user, CheckLogIn, admin } = useContext(AuthContext)

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px 20px 10px 20px",
          margin: "5px",
        }}
      >
        <RegionSelector
          mode="single"
          picker="range"
          layout="horizontal"
          setRange={setDateRange}
          setRegions={setRegion}
          setRegionsId={setRegionId}
          user={user}
          admin={admin}
        />
      </div>
      <DynamicMap region={region} regionId={regionId} dateRange={dateRange} />
    </>
  );
};

export default MapaDinamico;
