import React, { createContext, useState } from 'react';
import enGB from 'antd/lib/locale/en_GB';
import esES from 'antd/lib/locale/es_ES';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(esES);

  const changeLanguageAntd = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguageAntd }}>
      {children}
    </LanguageContext.Provider>
  );
};
