import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import CustomHeader from "../components/CustomHeader/CustomHeader";
import PublicHeader from "../components/CustomHeader/PublicHeaders";



const { Content } = Layout;


const PublicLayout = () => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <PublicHeader/>
      <Layout>
        <Content>
          <div
            style={{
              minHeight: 360,
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default PublicLayout;
