import { List } from "antd";
import { FaDownload } from "react-icons/fa";

const data = [
  {
    text: "Gas Regional",
  },
  {
    text: "Gas Global",
  },
];

const MapDownload = (props) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={<a href="#"><FaDownload/> {item.text } </a>}
          />
        </List.Item>
      )}
    ></List>
  );
};

export default MapDownload;
