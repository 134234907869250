import { Card } from "antd";
import { useParams } from "react-router-dom";
import { RiMapPinFill } from "react-icons/ri";
import CreateRegionForm from "../components/Forms/CreateRegionForm";
import CardTitle from "../components/UI/CardTitle";
import EditRegionForm from "../components/Forms/EditRegionForm";
import { useTranslation } from 'react-i18next';


const EditarRegion = ({admin}) => {

  const { t } = useTranslation();
  let { id } = useParams()

  return (
    <Card
      title={<CardTitle title={t('edit-region')} icon={RiMapPinFill} />}
      style={{ margin: "1rem" }}
    >
        <EditRegionForm regionId={id} admin={admin}/>
    </Card>
  );
};

export default EditarRegion;
