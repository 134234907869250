//React utilities
import { useState, useEffect } from "react";
//Hooks & Routing
import { useNavigate } from "react-router-dom";
//Components
import { Row, Form, Col, Input, Button, Dropdown, Menu, Modal, Card, Spin } from "antd";
import { FaEnvelope, FaIdCard, FaUserTag } from "react-icons/fa";
import config from "../../configs/config";
import { useTranslation } from 'react-i18next';


const EditUserForm = ({userId}) => {

  const { t } = useTranslation();
  const [userType, setUserType] = useState('Administrador')
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [renderKey, setRenderKey] = useState(0);
  const [usersData, setUsersData] = useState(false);

  let rol_user_id = 0

  const onFinish = (values) => {
    
    if (userType == 'Público') {
      rol_user_id = 1
    } else if (userType == 'Administrador') {
      rol_user_id = 2
    } 
    // else if (userType == 'Administrador') {
    //   rol_user_id = 3
    // }
    console.log('rol_user_id', rol_user_id)
    console.log("Received values of form: ", values);
    // setIsLoading(true);
    console.log('body', JSON.stringify({
      firstName: values.firstName,
      lastName: values.lastName,
      role_id: rol_user_id,
      id: usersData.id
    }))
    fetch(config.BACKEND_URL+"user/edit-user", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        firstName: values.firstName,
        lastName: values.lastName,
        role_id: rol_user_id,
        id: usersData.id
        // email: values.email,
        // password: values.password,
      }),
    })
      .then((response) => {
        // setIsLoading(false);
        if (response.ok) {
          const successModal = Modal.success({
            title: '',
            content: t("alert-msg.user-updated"), //'Ha editado correctamente al usuario',
          });
          setTimeout(() => {
            successModal.destroy();
            window.location.reload();
          }, 1800);
          return response.json();
        } else {
          return response.json().then((data) => {
            let errorMessage = "Error de autentificación";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            }
            throw new Error(errorMessage)
          });
        }
      })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        Modal.error({
          title: 'Error',
          content: err.message,
          centered: true,
          onOk: () => {
            // Cerrar modal después de 3 segundos
            setTimeout(() => {
              Modal.destroyAll();
            }, 1000);
          },
        });
      });
  };

  const getUser = async (userId) => {
    await fetch(config.BACKEND_URL+"user/get-all", {
      method: "GET",
    })
      .then((response) => {
        
        if (response.ok){
          // setLoggedIn(true);
          console.log(response, 'pruebaok');
          return response.json();
        }
        else{
          return false
        }
        
      }).then(data => {
        let userData = data.payload.find(dummy => dummy.id == userId)
        let rol_id = userData.role_id
        if (rol_id == 1) {
          rol_id = t('public')
        } else if (rol_id == 2) {
          rol_id = t('admin')
        } 
        setUsersData(userData)
        setUserType(rol_id)
        console.log('data desde users', usersData)
        return true
      } )
      .catch((err) => {
        return false;
      });
  };

  useEffect(() => {
    getUser(userId)
  }, [userId]);

  if (!usersData) {
    return (
      <Card style={{ margin: "1rem", height: "590px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin size="large" />
      </Card>
    );
  }

  const menu = (
    <Menu
      onClick={(e)=>(setUserType(e.key))}
      items={[
        {
          label: t('public'),
          key: "Público",
        },
        // {
        //   label:  t('country'),
        //   key: "País",
        // },
        {
          label: t('admin'),
          key: "Administrador",
        },
      ]}
    />
  );

  return (
    <Form layout={"vertical"} name="basic" form={form} key={renderKey}
      onFinish={onFinish} >
      <Form.Item
        label={t('email')}
        name="email"
        initialValue={usersData && usersData.email ? `${usersData.email}` : ''}
      >
        <Input addonBefore={<FaEnvelope />} disabled={true} />
      </Form.Item>
      <Form.Item
        label={t('first-name')}
        name="firstName"
        initialValue={usersData && usersData.firstName ? `${usersData.firstName}` : ''}
        rules={[
          {
            required: true,
            message: t('error-msg.invalid-fname'),
            min: 4,
          },
        ]}
      >
        <Input addonBefore={<FaIdCard />} />
      </Form.Item>
      <Form.Item
        label={t('last-name')}
        name="lastName"
        initialValue={usersData && usersData.lastName ? `${usersData.lastName}` : ''}
        rules={[
          {
            required: true,
            message: t('error-msg.invalid-lname'),
            min: 4,
          },
        ]}
      >
        <Input addonBefore={<FaIdCard />} />
      </Form.Item>
      <Form.Item label={t('user-type')}name='role_id'>
        <Dropdown placement="bottom" overlay={menu} trigger={"click"}>
          <Input className="input-dropdown" addonBefore={<FaUserTag />} value={userType} style={{cursor:"pointer"}}/>
        </Dropdown>
      </Form.Item>
      <Form.Item>
        <Row gutter={3}>
          <Col>
            <Button type="danger" onClick={() => window.location.reload()}>
            {t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
            {t('edit-user')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default EditUserForm;
