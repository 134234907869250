//React Utils
import { useState, useContext, useEffect } from "react";
import { Card, Form, Input, Checkbox, Typography, Button, Modal, Row, Col, Spin, Center } from "antd";
import {FaUser, FaEnvelope, FaIdCard, FaUserTag, FaCogs } from "react-icons/fa";
import CardTitle from "../../components/UI/CardTitle";
import AuthContext from "../../store/auth-context";
import config from "../../configs/config";
import { useTranslation } from 'react-i18next';

const Configuracion = (props) => {

  const { userId } = useContext(AuthContext)
  const { t } = useTranslation();

  console.log('user', userId)

  const [form] = Form.useForm();
  const [email, setEmail] = useState("admin@admin.cl");
  const [emailReport, setEmailReport] = useState(false);
  const [usersData, setUsersData] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  
  const handle_onCheckEmailReport = () => {
    setEmailReport((checked) => !checked);
  };
  const handle_onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const mapRoleToWord = (role_id) => {
  switch (role_id) {
    case 1:
      return t('public');
    case 2:
      return t('admin');
    // case 3:
    //   return t('admin');
    default:
      return '';
  }
  };

  const onFinish = (values) => {
    
    console.log("Received values of form: ", values);

    fetch(config.BACKEND_URL+"user/edit-user", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        firstName: values.firstName,
        lastName: values.lastName,
        id: usersData.id,
        password: values.confirm,
        recive_email_reports: emailReport,
      }),
    })
      .then((response) => {
        if (response.ok) {
          const successModal = Modal.success({
            title: '',
            content: t("alert-msg.profile-updated"),
          });
          setTimeout(() => {
            successModal.destroy();
            window.location.reload();
          }, 1800);
          return response.json();
        } else {
          return response.json().then((data) => {
            let errorMessage = "Error de autentificación";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            }
            throw new Error(errorMessage)
          });
        }
      })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        Modal.error({
          title: 'Error',
          content: err.message,
          centered: true,
          onOk: () => {
            // Cerrar modal después de 3 segundos
            setTimeout(() => {
              Modal.destroyAll();
            }, 1000);
          },
        });
      });
  };

  const getUser = async (userId) => {
    console.log('userId', userId)
    await fetch(config.BACKEND_URL+"user/get", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        id: userId
      }),
    })
      .then((response) => {
        if (response.ok){
          return response.json();
        }
        else{
          return false
        }
        
      }).then(data => {
        setUsersData(data.payload)
        setEmailReport(data.payload.recive_email_reports)
        return true
      } )
      .catch((err) => {
        setModalMessage(err.message);
        setIsModalVisible(true);
      });
  };

  useEffect(() => {
    getUser(userId)
  }, [userId]);

  if (!usersData) {
    return (
      <Card style={{ margin: "1rem", height: "590px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin size="large" />
      </Card>
    );
  }

  return (
    <Card style={{ margin: "1em" }} title={<CardTitle title={t('pro-settings')} icon={FaCogs} />}>
      <Form layout={"vertical"} name="basic" form={form} onFinish={onFinish}>
        <Form.Item
          label={t('first-name')}
          name="firstName"
          initialValue={usersData.firstName}
          rules={[
            {
              required: true,
              message: t('error-msg.invalid-fname'),
              min: 4,
            },
        ]}
      >
        <Input addonBefore={<FaIdCard />} />
      </Form.Item>
      <Form.Item
        label={t('last-name')}
        name="lastName"
        initialValue={usersData && usersData.lastName ? `${usersData.lastName}` : ''}
        rules={[
          {
            required: true,
            message: t('error-msg.invalid-lname'),
            min: 4,
          },
        ]}
      >
        <Input addonBefore={<FaIdCard />} />
      </Form.Item>
        <Form.Item
          label={t('email')}
          name="email"
          initialValue={usersData.email}
          rules={[
            {
              required: true,
              message: t('error-msg.invalid-email'),
              //pattern: /(\w+)@(\w+)\.(\w+)/,
              type: "email",
            },
          ]}
        >
          <Input
            addonBefore={<FaEnvelope/>}
            disabled={true}
            value={email}
            onChange={handle_onChangeEmail}
          />
        </Form.Item>
        <Form.Item label={t('user-type')}>
          <Input
            addonBefore={<FaUserTag/>}
            disabled={true}
            value={mapRoleToWord(usersData.role_id)}
          />
        </Form.Item>
        <Form.Item name="recive_emails">
          <Checkbox checked={emailReport} onChange={handle_onCheckEmailReport}>
            <Typography.Text>
            {t("enable-email-reports")}
            </Typography.Text>
          </Checkbox>
        </Form.Item>
        <Typography.Text
          style={{ color: "#17a2b8", fontSize: "0.8rem", fontWeigh: "400" }}
        >
          {t("enter-password-info")}
        </Typography.Text>
        <Form.Item
          name="pass"
          rules={[
            {
              message: t('error-msg.passw-inv'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  getFieldValue("pass").match(
                    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                  )
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    t('error-msg.passw-inv')
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={t("confirm-password")}
          dependencies={["pass"]}
          rules={[
            {
              message: ('error-msg.passw-dont-match'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pass") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('error-msg.passw-dont-match'))
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
        <Row gutter={3}>
          <Col>
            <Button type="danger" onClick={() => window.location.reload()}>
            {t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
            {t('update')}
            </Button>
          </Col>
        </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Configuracion;
