//React utilities
import { useState, useContext } from "react";
//Components
import { Popover, Button, Divider, ConfigProvider } from "antd";
import { FaLanguage } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import enGB from 'antd/lib/locale/en_GB';
import esES from 'antd/lib/locale/es_ES';
import { LanguageContext } from "../../context/LangContext";

const LanguageSelector = () => {

  const { changeLanguageAntd } = useContext(LanguageContext)
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const handleLanguage = (language) => {
    i18n.changeLanguage(language);

    if (language === 'es') {
      changeLanguageAntd(esES);
    } else if (language === 'en') {
      changeLanguageAntd(enGB);
    }
  };

  const menu = (
    <div onClick={() => setVisible(false)}>
      <p onClick={() => handleLanguage('es')} style={{ margin: '5px', cursor: 'pointer' }}>
        Español
      </p>
      <Divider style={{ margin: '4px' }} />
      <p onClick={() => handleLanguage('en')} style={{ margin: '5px', cursor: 'pointer' }}>
        English
      </p>
    </div>
  );

  return (
    <Popover
      onVisibleChange={handleVisibleChange}
      visible={visible}
      content={menu}
      trigger="click"
      className="text_header"
    >
      <Button
        icon={
          <FaLanguage
            style={{
              paddingRight: '5px',
              fontSize: '2.7em',
              verticalAlign: 'middle',
            }}
          />
        }
        style={{ padding: '5px', marginTop: '5px' }}
        type="text"
      >
        {i18n.language === 'es' ? 'Español' : 'English'}
      </Button>
    </Popover>
  );
};

export default LanguageSelector;
