//React utilities
import { useState } from "react";
//Hooks & Routing
import { useNavigate } from "react-router-dom";
//Components
import { Row, Form, Col, Input, Button, Dropdown, Menu, Modal } from "antd";
import { FaEnvelope, FaIdCard, FaUserTag } from "react-icons/fa";
import config from "../../configs/config";
import { useTranslation } from "react-i18next";

const CreateUserForm = (props) => {
    const { t } = useTranslation();
    const [userType, setUserType] = useState(t("admin"));
    const navigate = useNavigate();
    const [form] = Form.useForm();

    let rol_user_id = 1;
    let sudo = false;

    const onFinish = (values) => {
        if (userType == "Público") {
            rol_user_id = 1;
            sudo = false;
        } else if (userType == "Administrador") {
            rol_user_id = 2;
            sudo = true;
        }
        /* console.log('rol_user_id', rol_user_id)
    console.log("Received values of form: ", values);
    // setIsLoading(true);
    */
        console.log(
            "body",
            JSON.stringify({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                role_id: rol_user_id,
                sudo: sudo,
            })
        );
        fetch(config.BACKEND_URL + "user/create-user", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
            },
            credentials: "include",
            body: JSON.stringify({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                role_id: rol_user_id,
                sudo: sudo,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    const successModal = Modal.success({
                        title: "",
                        content: t("alert-msg.new-user"),
                    });
                    setTimeout(() => {
                        successModal.destroy();
                        window.location.reload();
                    }, 1800);
                    return response.json();
                } else {
                    return response.json().then((data) => {
                        let errorMessage = "Error de autentificación";
                        if (data && data.error && data.error.message) {
                            errorMessage = data.error.message;
                        }
                        throw new Error(errorMessage);
                    });
                }
            })
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                Modal.error({
                    title: "Error",
                    content: err.message,
                    centered: true,
                    onOk: () => {
                        // Cerrar modal después de 3 segundos
                        setTimeout(() => {
                            Modal.destroyAll();
                        }, 1000);
                    },
                });
            });
    };

    const menu = (
        <Menu
            onClick={(e) => setUserType(e.key)}
            items={[
                {
                    label: t("public"),
                    key: "Público",
                },
                /*         {
          label: t('country'),
          key: "País",
        }, */
                {
                    label: t("admin"),
                    key: "Administrador",
                },
            ]}
        />
    );
    return (
        <Form layout={"vertical"} name="basic" form={form} onFinish={onFinish}>
            <Form.Item
                label={t("email")}
                name="email"
                rules={[
                    {
                        required: true,
                        message: t("error-msg.invalid-email"),
                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        type: "email",
                    },
                ]}
            >
                <Input addonBefore={<FaEnvelope />} />
            </Form.Item>
            <Form.Item
                label={t("first-name")}
                name="firstName"
                initialValue={""}
                rules={[
                    {
                        required: true,
                        message: t("error-msg.invalid-fname"),
                        min: 4,
                    },
                ]}
            >
                <Input addonBefore={<FaIdCard />} />
            </Form.Item>
            <Form.Item
                label={t("last-name")}
                name="lastName"
                initialValue={""}
                rules={[
                    {
                        required: true,
                        message: t("error-msg.invalid-lname"),
                        min: 4,
                    },
                ]}
            >
                <Input addonBefore={<FaIdCard />} />
            </Form.Item>
            <Form.Item label={t("user-type")}>
                <Dropdown placement="bottom" overlay={menu} trigger={"click"}>
                    <Input className="input-dropdown" addonBefore={<FaUserTag />} value={userType} style={{ cursor: "pointer" }} />
                </Dropdown>
            </Form.Item>
            <Form.Item>
                <Row gutter={3}>
                    <Col>
                        <Button type="danger" onClick={() => window.location.reload()}>
                            {t("cancel")}
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            {t("create-user")}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default CreateUserForm;
