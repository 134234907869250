import React, { useEffect, useRef, useState, useContext } from "react";
import { Slider, Card, Row, Col, Input } from "antd";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";
import { RMap, ROSM, RControl } from "rlayers";

import CSSTransition from "react-transition-group/CSSTransition";

import { RScaleLine } from "rlayers/control";
import config from "../../configs/config";
import { fetchGasConfig } from "../../helper/api";
import ColorbarLegend from "./ColorbarLegend";
import GeoserverLayer from "./GeoserverLayer";
import MapStatistics from "./MapStatistics";
import MapColorSettings from "./MapColorSettings";
import AuthContext from "../../store/auth-context";
import { FaEllipsisH, FaChartBar, FaPaintRoller } from "react-icons/fa";
import "./Maps.css";

const center = fromLonLat([-70.498586, -15.072488]);

const { RCustom, RFullScreen } = RControl;

const formatActualDate = (current_date) => {
  if (current_date) {
    let mm = undefined;
    let yyyy = undefined;
    if (current_date.getMonth() <= 10) {
      mm = current_date.getMonth() + 1;
      yyyy = current_date.getFullYear();
    } else {
      mm = 0;
      yyyy = current_date.getFullYear() + 1;
    }
    return mm + 1 + "/" + yyyy;
  } else {
    return "";
  }
};

const getUserConfig = async (setState) => {
  try {
    const response = await fetch(config.BACKEND_URL + "maps/gas/get-config", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        gas: 1,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.status === "fail") {
        return getDefaultConfig(setState);
      }
      console.log("data desde map config user", data);
      setState(data.payload);
      return true;
    }
  } catch (err) {
    console.log("err", err);
    console.log("PASO");
    return getDefaultConfig(setState);
  }
};

const getStatsByDateId = async (setState, region_id, date) => {
  if (date) {
    let date_str = "";
    date_str = date_str + date.year().toString() + "-";
    if (date.month() <= 8) {
      date_str = date_str + "0" + date.month().toString() + "-";
    } else {
      date_str = date_str + (date.month() + 1).toString() + "-";
    }
    date_str = date_str + "01";
    console.log("region_id", region_id);
    console.log("date_str", date_str);
    console.log(date_str);
    await fetch(config.BACKEND_URL + "maps/stats/get-by-id-and-date", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
      body: JSON.stringify({
        initialDate: date_str,
        regions: [region_id],
        endingDate: date_str,
        gas: 1,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // setLoggedIn(true);
          console.log(response, "pruebaok");
          return response.json();
        } else {
          return false;
        }
      })
      .then((data) => {
        // setUsersData(data.payload)
        console.log("data desde getStatsByDateId", data);
        try {
          const stats_fetched = data.payload[0];
          console.log(stats_fetched);
          setState({
            min_value: stats_fetched.min_value,
            max_value: stats_fetched.max_value,
            mean_value: stats_fetched.mean_value,
            std_value: stats_fetched.std_value,
          });
          return true;
        } catch {
          return false;
        }
      })
      .catch((err) => {
        console.log("err", err);
        return false;
      });
  }
};

const getDefaultConfig = async (setState) => {
  await fetch(config.BACKEND_URL + "maps/gas/default/get-config", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
    },
    credentials: "include",
    body: JSON.stringify({
      gas: 1,
    }),
  })
    .then((response) => {
      if (response.ok) {
        // setLoggedIn(true);
        console.log(response, "pruebaok");
        return response.json();
      } else {
        return false;
      }
    })
    .then((data) => {
      // setUsersData(data.payload)
      console.log("data desde map config default", data);
      setState(data.payload);
      return true;
    })
    .catch((err) => {
      console.log("err", err);
      return false;
    });
};

const format_date = (date) => {
  if (date) {
    let formated_date = new Date(date.year(), date.month(), 1, 0, 0, 0);
    formated_date = new Date(
      formated_date.getFullYear(),
      formated_date.getMonth(),
      1,
      -formated_date.getUTCHours(),
      0,
      0
    );
    return formated_date;
  } else {
    return new Date(1, 1, 1);
  }
};

const DynamicMap = ({ region, regionId, dateRange }) => {
  console.log(dateRange);
  let dateRange_formatted = [];
  if (dateRange) {
    dateRange_formatted = dateRange.map((date) => format_date(date));
  }

  const { user } = useContext(AuthContext);
  const [colorSettings, setColorSettings] = useState({});
  const [stats, setStats] = useState({
    min_value: undefined,
    max_value: undefined,
    mean_value: undefined,
    std_value: undefined,
  });
  const [activeTab, setActiveTab] = useState("stats");
  const [show, setShow] = useState(false);
  const onTabChange = (key) => {
    setActiveTab(key);
  };
  const toggleShow = () => {
    setShow((prev_show) => !prev_show);
  };
  useEffect(() => {
    if (user) {
      getUserConfig(setColorSettings);
    } else {
      getDefaultConfig(setColorSettings);
    }
  }, []);
  const contentList = {
    stats: (
      <MapStatistics
        min={stats.min_value}
        max={stats.max_value}
        mean={stats.mean_value}
        dev={stats.std_value}
      />
    ),
    color: (
      <MapColorSettings values={colorSettings} setValues={setColorSettings} />
    ),
  };

  const tabList = [
    {
      key: "stats",
      tab: <FaChartBar />,
    },
    {
      key: "color",
      tab: <FaPaintRoller />,
    },
  ];

  const actualDate_ref = useRef();
  const [iDate, setIDate] = useState(0);
  useEffect(() => {
    if (iDate && dateRange) {
      getStatsByDateId(setStats, regionId, dateRange[iDate]);
      console.log(stats);
    }
  }, [regionId, iDate]);
  useEffect(() => {
    if (actualDate_ref.current) {
      actualDate_ref.current.input.value = formatActualDate(
        dateRange_formatted[0]
      );
    }
    setIDate(0);

    getUserConfig();
  }, [region]);

  useEffect(() => {
    if (actualDate_ref.current && dateRange) {
      actualDate_ref.current.input.value = formatActualDate(
        dateRange_formatted[iDate]
      );
    }
  }, [iDate]);

  useEffect(() => {
    if (actualDate_ref.current && dateRange) {
      actualDate_ref.current.input.value = formatActualDate(
        dateRange_formatted[iDate]
      );
    }
  }, [iDate, colorSettings, show, activeTab]);

  let ndates = 0;
  if (dateRange) {
    ndates = dateRange_formatted.length - 1;
  }
  const onChange_Handler = (newIDate) => {
    setIDate(newIDate);
  };
  return (
    <React.Fragment>
      <RMap
        width={"100%"}
        height={"calc(100vh - 16em)"}
        initial={{ center: center, zoom: 3.5 }}
      >
        <RFullScreen />
        <ROSM />
        <RCustom className={"customControl"}>
          <button onClick={toggleShow}>
            <FaEllipsisH />
          </button>
        </RCustom>

        <ColorbarLegend
          min={colorSettings["value_min"]}
          max={colorSettings["value_max"]}
          palette={colorSettings["map_style"]}
        />

        {dateRange && (
          <>
            <GeoserverLayer
              min={colorSettings["value_min"]}
              max={colorSettings["value_max"]}
              region={region}
              date={dateRange_formatted[iDate]}
              palette={colorSettings["map_style"]}
            />
            <RCustom className="slider_container">
              <div
                size="small"
                style={{ marginTop: "5px", padding: "5px 10px 5px 10px" }}
              >
                <Row justify="center" align="middle">
                  <Input
                    value={formatActualDate(dateRange_formatted[iDate])}
                    style={{
                      width: "5em",
                      borderRadius: "5px",
                      backgroundColor: "rgb(0,0,0,0.8)",
                      color: "white",
                      cursor: "default",
                    }}
                    disabled
                  />
                </Row>
                <Row justify="center" gutter={5}>
                  <Col style={{ width: "7em" }}>
                    <Input
                      //value={dateRange[0].month() + 1 + "/" + dateRange[0].year()}
                      value={formatActualDate(dateRange_formatted[0])}
                      style={{
                        width: "6em",
                        borderRadius: "5px",
                        backgroundColor: "rgb(0,0,0,0.8)",
                        color: "white",
                        cursor: "default",
                      }}
                      disabled
                    />
                  </Col>
                  <Col style={{ width: "calc(100% - 15em)" }}>
                    <Slider
                      trackStyle={{ backgroundColor: "rgb(0,0,0,0.8)" }}
                      handleStyle={{
                        borderColor: "rgb(0,0,0,0.8)",
                        zIndex: "10",
                      }}
                      min={0}
                      max={dateRange_formatted.length - 1}
                      onChange={onChange_Handler}
                      value={iDate}
                      tooltipPlacement="bottom"
                      tipFormatter={null}
                    />
                  </Col>
                  <Col style={{ width: "5em" }}>
                    <Input
                      //value={dateRange[1].month() + 1 + "/" + dateRange[1].year()}
                      value={formatActualDate(
                        dateRange_formatted[dateRange_formatted.length - 1]
                      )}
                      style={{
                        width: "6em",
                        borderRadius: "5px",
                        backgroundColor: "rgb(0,0,0,0.8)",
                        color: "white",
                        cursor: "default",
                      }}
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            </RCustom>
          </>
        )}
        <RScaleLine bar={true} />
        <CSSTransition
          mountOnEnter
          unmountOnExit
          in={show}
          timeout={400}
          classNames="configCardAnimation"
        >
          <Card
            activeTabKey={activeTab}
            tabList={tabList}
            onTabChange={onTabChange}
            className="configCard"
          >
            {contentList[activeTab]}
          </Card>
        </CSSTransition>
      </RMap>
    </React.Fragment>
  );
};

export default DynamicMap;
