import config from "../configs/config";

export const GET_CONFIG = {
  method: "GET",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
  },
  credentials: "include",
};

const POST_CONFIG = {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
  },
  credentials: "include",
};


export const fetchRegions = async (logedUser, isAdmin) => {

  let regionListUrl = '';

  if (logedUser && isAdmin) {
    regionListUrl = "maps/region/get-all-list";
  } else if (logedUser) {
    regionListUrl = "maps/region/get-user-list";
  } else {
    regionListUrl = "maps/region/get-list";
  }
  
  console.log('regionListUrl', regionListUrl)
  const regiones_promise = await fetch(

    config.BACKEND_URL + regionListUrl,
    GET_CONFIG
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return false;
      }
    })
    .then((data) => {
      return data.payload;
    })
    .catch((err) => {
      console.log(err, "Error al consultar Regiones");
      return false;
    });
  return regiones_promise;
};

export const fetchGasConfig = async () => {
  const regiones_promise = await fetch(
    config.BACKEND_URL + "maps/gas/get-config",
    {
      body: JSON.stringify({ "gas": 1 }),
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return false;
      }
    })
    .then((data) => {
      return data.payload;
    })
    .catch((err) => {
      console.log(err, "Error al consultar Configuracion Gas");
      return false;
    });
  return regiones_promise;
};

