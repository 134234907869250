//Components
import { Card } from "antd";
import CardTitle from "../../components/UI/CardTitle";
import { FaUserPlus } from "react-icons/fa";
import CreateUserForm from "../../components/Forms/CreateUserForm";
import { useTranslation } from 'react-i18next';

const CrearUsuario = () => {
    const { t } = useTranslation();
    return (
      <Card
        title={<CardTitle title={t("create-users")} icon={FaUserPlus} />}
        style={{ margin: "1rem" }}
      >
        <CreateUserForm/>
      </Card>
    )
}

export default CrearUsuario;