import React,{ useEffect, useState } from "react";
import { RContext } from "rlayers";
import TileWMS from "ol/source/TileWMS";
import TileLayer from "ol/layer/Tile";
import config from "../../configs/config";
import {transformExtent} from "ol/proj"
import {Image} from "ol/layer"

const GeoserverLayer = (props) => {
  const [prevLayer, setPrevLayer] = useState(undefined);
  const [newLayer, setNewLayer] = useState(undefined);


  useEffect(() => {
    if (props.date){
    setPrevLayer(newLayer);
    setNewLayer(
      new TileLayer({
        source: new TileWMS({
          url: config.GEOSERVER_URL + "wms",
          params: {
            LAYERS: "CEPAL:" + props.region,
            TIME: props.date.toISOString(),
            env: "min:" + props.min + ";max:" + props.max,
            STYLES: props.palette,
          },
        }),
      })
    );}
  }, [props.date, props.region]);

  return (
    <RContext.Consumer>
      {({ map }) => {
        const layers = map.getAllLayers();
        if (prevLayer !== undefined) {
          map.removeLayer(prevLayer);
        }
        if (newLayer !== undefined && !layers.includes(newLayer) ) {
          map.addLayer(newLayer);
          

        //   let layer2 = new ol.layer.Image({
        //     title: 'zone',
        //     visible: false,
        //     extent: extent_3857
        // });
          
        }
      }}
    </RContext.Consumer>
  );
};

export default React.memo(GeoserverLayer);
