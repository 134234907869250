import { useState, useContext, useEffect, useRef, useCallback } from "react";
import AuthContext from "../../store/auth-context";
import LoadingComponent from "./LoadingComponent";
import { Navigate } from "react-router-dom";
import Timeout from 'react-timeout';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const ProtectedView = (props) => {

  const { t } = useTranslation();
  const { CheckLogIn, isLoggedIn, logout } = useContext(AuthContext);
  const { children } = props;
  const [fetching, setFetching] = useState(true);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const timeoutRef = useRef(null);

  let segundosEspera = 30*60

  const Check = async () => {
    setFetching(true);
    await CheckLogIn();
    setFetching(false);
  };

  useEffect(() => {
    Check();
  }, []);

  useEffect(() => {
    let timeoutId;
    if (!fetching && !isLoggedIn) {
      timeoutId = setTimeout(() => {
        setShouldNavigate(true);
      }, 50);
    }
    return () => clearTimeout(timeoutId);
  }, [fetching, isLoggedIn]);

  const handleActivity = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setModalVisible(true);
    }, segundosEspera * 1000); // Resetear el temporizador a 30 segundos después de cada actividad
  };

  const handleLogout = () => {
    setModalVisible(false);
    logout();
    setShouldNavigate(true);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("click", handleActivity);
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, [handleActivity]);

  useEffect(() => {
    if (modalVisible) {
      timeoutRef.current = setTimeout(() => {
        handleLogout();
      }, 5000); // Cerrar sesión automáticamente después de 15 segundos de mostrar el modal
    }
    return () => clearTimeout(timeoutRef.current);
  }, [modalVisible]);

  return (
    <>
      {fetching && <LoadingComponent />}
      {!fetching && isLoggedIn && (
        <>
          {children}

          <Modal
            visible={modalVisible}
            title={t("alert-msg.are-you-there")}
            okText={t("continue")}
            cancelText={t("logout")}
            onCancel={handleLogout}
            onOk={() => {
              setModalVisible(false);
              handleActivity();
              Check();
            }}
          >
            <p>
            {t("alert-msg.session-expiring")}
            </p>
          </Modal>
        </>
      )}
      {shouldNavigate && <Navigate replace to="/login" />}
    </>
  );
};

export default Timeout(ProtectedView);

