//Components
import { Card, Col, Row } from "antd";
import DUMMY_DATA from "../assets/dumy-data-1.json";
import { Line, Pie } from "react-chartjs-2";
import CardTitle from "../components/UI/CardTitle";
import { FaChartPie } from "react-icons/fa";
import Chart from "chart.js/auto";
import RegionSelector from "../components/RegionSelector/RegionSelector";
import StatisticsTable from "../components/Tables/StatisticsTable";
import { useTranslation } from "react-i18next";
import AuthContext from "../store/auth-context";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Radio } from "antd";

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// Función para generar un color RGB aleatorio
function getRandomColor() {
    var r = getRandomNumber(0, 255);
    var g = getRandomNumber(0, 255);
    var b = getRandomNumber(0, 255);
    return "rgb(" + r + ", " + g + ", " + b + ")";
}

const Graficos = () => {
    const { t } = useTranslation();
    const { user, CheckLogIn, admin } = useContext(AuthContext);
    const [fetchGraficos, setFetchGraficos] = useState(null);
    const [graphOption, setGraphOption] = useState("total_value");

    const handleDataFetch = (data) => {
        setFetchGraficos(data);
    };

    useEffect(() => {
        console.log("fetchGraficos desde graficos", fetchGraficos);
    }, [fetchGraficos]);

    const handleChangeGraph = (value) => {
        setGraphOption(value.target.value);
    };
    /* var datasets = {
    id: fetchGraficos ? fetchGraficos.map((e) => e.id) : null,
    fill: false,
    label: fetchGraficos ? fetchGraficos.map((e) => e.region_long) : null,
    data: fetchGraficos ? fetchGraficos.map((e) => e.mean_value) : null,
    borderColor: getRandomColor(),
    // backgroundColor: 'rgba(53, 162, 235, 0.5)',
  }

  const data = {
    labels:  fetchGraficos ? fetchGraficos.map((e) => moment(e.date).format("YYYY-MM-DD")) : null,
    datasets: [datasets]
  }; */
    const basicOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Produccion CO2",
            },
        },
        scales: {
            y: {
                display: true,
                title: {
                    display: true,
                    text: t("flow-chart-label"),
                },
            },
        },
    };

    const generalOptions = {
        total_value: t("total-chart-label"),
        mean_value: t("mean-chart-label"),
        max_value: t("max-chart-label"),
        min_value: t("min-chart-label"),
        std_value: t("std-chart-label"),
    };

    const labeledDataArray = fetchGraficos
        ? fetchGraficos.map((obj) => {
              return {
                  date: moment(obj.date).format("YYYY-MM-DD"),
                  data: obj,
              };
          })
        : null;

    let labels = null;

    if (labeledDataArray) {
        labeledDataArray.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
        labels = labeledDataArray.map((obj) => obj.date);
    }

    const uniqueLabels = fetchGraficos ? [...new Set(fetchGraficos.map((obj) => obj.region_long))] : null;

    const datasets = uniqueLabels
        ? uniqueLabels.map((label) => {
              return {
                  id: fetchGraficos ? fetchGraficos.map((e) => e.id) : null,
                  fill: false,
                  label: generalOptions[graphOption],
                  data: labeledDataArray
                      ? labeledDataArray.filter((obj) => obj.data.region_long === label).map((obj) => obj.data[graphOption])
                      : null,
                  borderColor: getRandomColor(),
              };
          })
        : null;

    console.log(graphOption, datasets);
    const data = {
        labels: labels,
        datasets: datasets,
    };

    const chartOptions = basicOptions;
    const chartData = data ? data : null;
    return (
        <div style={{ height: "92vh", overflow: "auto" }}>
            <Card style={{ margin: "1rem" }} title={<CardTitle title={t("ch-and-st")} icon={FaChartPie} />}>
                <RegionSelector
                    // mode="multiple"
                    user={user}
                    admin={admin}
                    graficos={true}
                    handleDataFetch={handleDataFetch}
                />

                <Card type="inner" style={{ marginBottom: "15px" }}>
                    <StatisticsTable data={fetchGraficos} />
                </Card>

                {fetchGraficos && (
                    <Card type="inner" style={{ marginBottom: "15px" }}>
                        <Row gutter={20} justify="center">
                            <Radio.Group defaultValue="total" buttonStyle="solid" onChange={handleChangeGraph}>
                                <Radio.Button value="mean_value">{t("mean-chart-option")}</Radio.Button>
                                <Radio.Button value="max_value">{t("max-chart-option")}</Radio.Button>
                                <Radio.Button value="min_value">{t("min-chart-option")}</Radio.Button>
                                <Radio.Button value="total_value">{t("total-chart-option")}</Radio.Button>
                                <Radio.Button value="std_value">{t("std-chart-option")}</Radio.Button>
                            </Radio.Group>
                        </Row>
                    </Card>
                )}
                <Row justify="center">
                    {fetchGraficos && (
                        <Col span={16}>
                            <Card type="inner">
                                <Line data={chartData} options={chartOptions}></Line>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Card>
        </div>
    );
};

export default Graficos;
