import { useContext } from "react";
//Components
import { Popover, Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import AuthContext from "../../store/auth-context";
import { useTranslation } from 'react-i18next';



const UserAvatarHeader = () => {
  
  const { t } = useTranslation();
  const {logout, user} = useContext(AuthContext)

  return (
    <Popover
      content={ <Button type='text' onClick={logout} style={{margin:"2px"}}>{t("logout")}</Button> }
      trigger={"click"}
      className={"text_header"}
    >
      <Button
        icon={<FaUser style={{ paddingRight: "5px", marginBottom: '2px', fontSize:"1.3em", verticalAlign:"middle" }} />}
        className={"button_header"}
        type={"text"}
      >
        {user}
      </Button>
    </Popover>
  );
};

export default UserAvatarHeader;
