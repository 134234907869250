import React from "react";
//Import antd
import { Card } from "antd";
import CardTitle from "../../components/UI/CardTitle";
import { FaAtlas } from "react-icons/fa";
import RegionsTable from "../../components/Tables/RegionsTable";
import { useTranslation } from 'react-i18next';

const MisRegiones = () => {
  const { t } = useTranslation();
  return (
    <Card
      title={<CardTitle title={t("my-regions")} icon={FaAtlas} />}
      style={{ margin: "1rem" }}
    >
      <RegionsTable admin={false} />
    </Card>
  );
};

export default MisRegiones;
