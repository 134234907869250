import { useState } from "react";
import { Form, Select, DatePicker, Row, Col, Button } from "antd";
import { FaSearch } from "react-icons/fa";
import { fetchRegions } from "../../helper/api";
import { useTranslation } from "react-i18next";
import config from "../../configs/config";
import "./RegionSelector.css";
import { useEffect } from "react";

const { Option } = Select;

const HOURS_OFFSET = new Date(2000, 1, 1, 0, 0, 0).getUTCHours();

const fetchForCharts = async (range, region, onDataFetch) => {
    let regionListUrlStats;
    let body;
    //console.log('date', range[1])
    //console.log('date', range[1].add(30, 'd'))
    /*  function convertirComillas(array) {
    var arrayConComillasDobles = array.map(function(item) {
      return item.replace(/'/g, '"');
    });
    return arrayConComillasDobles;
  }

  console.log('region', convertirComillas(region)) */
    // console.log('range1', range[0].toISOString())
    // console.log('range2', range[1].toISOString())

    if (range && region) {
        regionListUrlStats = "maps/stats/get-by-id-and-date";
        body = {
            gas: 1,
            regions: [region],
            initialDate: range[0].toISOString(),
            endingDate: range[1].add(1, "m").toISOString(),
        };
    } else if (!range && region) {
        regionListUrlStats = "maps/stats/get-by-id";
        body = {
            regions: [region],
            gas: 1,
        };
    }

    console.log("regionListUrlChats", regionListUrlStats);
    console.log(
        "body charts",
        JSON.stringify({
            body,
        })
    );

    const regiones_promise = await fetch(config.BACKEND_URL + regionListUrlStats, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
        },
        credentials: "include",
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return false;
            }
        })
        .then((data) => {
            console.log("data.payload", data.payload);
            onDataFetch(data.payload);
            return data.payload;
        })
        .catch((err) => {
            console.log(err, "Error al consultar Regiones");
            return false;
        });
    return regiones_promise;
};

const RegionSelector = (props) => {
    const { t } = useTranslation();
    const [dateInfo, setDateInfo] = useState([]);
    const [intDates, setIntDates] = useState([]);

    const fetchDates = async (region) => {
        console.log("fetch");
        await fetch(config.GEOSERVER_URL + "rest/workspaces/CEPAL/coveragestores/latam/coverages/latam/index/granules.json", {
            method: "GET",
            mode: "cors",

            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
                Authorization: "Basic " + btoa("user" + ":" + "myawesomegeoserver"),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.ok) {
                    // setLoggedIn(true);
                    return response.json();
                } else {
                    return false;
                }
            })
            .then((data) => {
                // setUsersData(data.payload)
                console.log("data desde fetch dates", data);
                const features = data.features;
                const dates = features.map((e) => {
                    const isostring = e.properties.ingestion;
                    const temp_date = new Date(isostring);
                    let formated_date = new Date(temp_date.getFullYear(), temp_date.getMonth(), 25, -HOURS_OFFSET);
                    return formated_date;
                });
                const intDates = dates.map((e) => e.getFullYear() * 100 + e.getMonth());
                setDateInfo(dates);
                setIntDates(intDates);
                return true;
            })
            .catch((err) => {
                console.log("err", err);
                return false;
            });
    };

    const [regiones, setRegiones] = useState([]);
    const [form] = Form.useForm();
    const mode = props.mode;
    const picker = props.picker;
    const layout = props.layout;
    const setRange = props.setRange;
    const setRegions = props.setRegions;
    const setRegionsId = props.setRegionsId;
    const searchPosition = props.searchPosition ? props.searchPosition : "bottom";
    const logedUser = props.user ? props.user : undefined;
    const isAdmin = props.admin;
    const isGrafic = props.graficos ? props.graficos : false;
    const onDataFetch = props.handleDataFetch;

    let regionWidth = undefined;

    const disabledDate = (e) => {
        let date_year = e.year();
        let date_month = e.month();
        if (date_month == 0) {
            date_year = date_year - 1;
            date_month = 11;
        } else {
            date_month = date_month - 1;
        }
        // Can not select days before today and today
        return !intDates.includes(date_year * 100 + date_month);
    };

    useEffect(() => {
        const getRegions = () => {
            fetchRegions(logedUser, isAdmin).then((data) => {
                setRegiones(data);
            });
        };
        getRegions();
    }, [props.user]);

    if (mode === "multiple" || (layout === "horizontal" && searchPosition !== "bottom")) {
        regionWidth = 12;
    } else {
        regionWidth = 6;
    }
    const title = mode === "multiple" ? t("regions-to-consider") : t("region-to-consider");

    const options = isGrafic
        ? regiones.map((region_dict) => {
              return { value: region_dict.id, label: region_dict.short_name };
          })
        : regiones.map((region_dict) => {
              return { value: region_dict.geoserver_id + "|" + region_dict.id, label: region_dict.short_name };
          });
    return (
        <>
            <Form form={form} layout={layout} className="form_region_selector">
                <Row gutter={"20"}>
                    <Col span={layout === "vertical" ? 24 : 12}>
                        <Form.Item rules={[{ required: true, message: t("error-msg.no-region") }]} name="region" label={title}>
                            <Select
                                //mode={mode}
                                showSearch
                                allowClear
                                style={{
                                    width: "100%",
                                }}
                                placeholder={t("select-pls")}
                                onChange={() => fetchDates()}
                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                }
                                options={options}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={layout === regionWidth}>
                        <Form.Item
                            name="date-range"
                            label={picker === "month" ? t("date") : t("time-interval")}
                            rules={[
                                {
                                    required: isGrafic ? false : true,
                                    message: t("error-msg.no-date"),
                                },
                            ]}
                        >
                            {picker !== "month" ? (
                                <DatePicker.RangePicker
                                    // placeholder='from'
                                    disabledDate={(e) => {
                                        return disabledDate(e);
                                    }}
                                    picker="month"
                                />
                            ) : (
                                <DatePicker
                                    // placeholder='d'
                                    disabledDate={(e) => {
                                        return disabledDate(e);
                                    }}
                                    picker="month"
                                />
                            )}
                        </Form.Item>
                    </Col>
                    {searchPosition !== "bottom" && layout === "horizontal" ? (
                        <Col span={6}>
                            <Form.Item name="search" dependencies={["date-range", "region"]}>
                                <Button
                                    onClick={() => {
                                        form.validateFields(["date-range", "region"]);
                                        if (setRange && setRegions) {
                                            const range = form.getFieldValue("date-range");
                                            if (picker !== "month") {
                                                range.sort((a, b) => a - b);
                                                const fdate = range[1];
                                                let temp = range[0];
                                                let moment_range = [];
                                                while (temp <= fdate) {
                                                    moment_range.push(temp);
                                                    temp = temp.clone().add(1, "month");
                                                }

                                                moment_range = moment_range.map((e) => {
                                                    let date_year = e.year();
                                                    let date_month = e.month();
                                                    if (date_month == 0) {
                                                        date_year = date_year - 1;
                                                        date_month = 11;
                                                    } else {
                                                        date_month = date_month - 1;
                                                    }
                                                    if (intDates.includes(date_year * 100 + date_month)) {
                                                        return e;
                                                    }
                                                });
                                                moment_range = moment_range.filter(function (element) {
                                                    return element !== undefined;
                                                });
                                                console.log(moment_range);
                                                setRange(moment_range);
                                            } else {
                                                console.log(range);
                                                setRange(range);
                                            }

                                            const region = form.getFieldValue("region");
                                            console.log(region);
                                            if (typeof region === "object") {
                                                setRegions(region.map((e) => e.split("|")[0]));
                                                setRegionsId(region.map((e) => e.split("|")[1]));
                                            } else {
                                                setRegions(region.split("|")[0]);
                                                setRegionsId(region.split("|")[1]);
                                            }
                                        }
                                    }}
                                    type="primary"
                                    style={{ width: "100%" }}
                                >
                                    <FaSearch style={{ marginRight: "5px" }} />
                                    {t("search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    ) : undefined}
                </Row>
                {searchPosition === "bottom" ? (
                    <Row style={{ marginTop: "15px" }}>
                        <Col span={24}>
                            <Form.Item name="search" dependencies={["date-range", "region"]}>
                                {isGrafic ? (
                                    <Button
                                        onClick={() => {
                                            form.validateFields(["date-range", "region"]);
                                            const range = form.getFieldValue("date-range");
                                            const region = form.getFieldValue("region");
                                            fetchForCharts(range, region, onDataFetch);
                                        }}
                                        type="primary"
                                        style={{ width: "100%" }}
                                    >
                                        <FaSearch style={{ marginRight: "5px" }} />
                                        {t("search")}
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            const region = form.getFieldValue("region");
                                            form.validateFields(["date-range", "region"]);
                                            if (setRange && setRegions) {
                                                const range = form.getFieldValue("date-range");

                                                range.sort((a, b) => a - b);
                                                const ini = range[0];
                                                const fdate = range[1];
                                                let temp = range[0];
                                                let moment_range = [];
                                                while (temp <= fdate) {
                                                    moment_range.push(temp);
                                                    temp = temp.clone().add(1, "month");
                                                }
                                                moment_range = moment_range.map((e) => {
                                                    let date_year = e.year();
                                                    let date_month = e.month();
                                                    if (date_month == 0) {
                                                        date_year = date_year - 1;
                                                        date_month = 11;
                                                    } else {
                                                        date_month = date_month - 1;
                                                    }
                                                    if (intDates.includes(date_year * 100 + date_month)) {
                                                        return e;
                                                    }
                                                });
                                                moment_range = moment_range.filter(function (element) {
                                                    return element !== undefined;
                                                });
                                                console.log(moment_range);
                                                setRange(moment_range);
                                                if (typeof region === "object") {
                                                    setRegions(region.map((e) => e.split("|")[0]));
                                                    setRegionsId(region.map((e) => e.split("|")[1]));
                                                } else {
                                                    setRegions(region.split("|")[0]);
                                                    setRegionsId(region.split("|")[1]);
                                                }
                                            }
                                        }}
                                    >
                                        <FaSearch style={{ marginRight: "5px" }} />
                                        {t("search")}
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                ) : undefined}
            </Form>
        </>
    );
};

RegionSelector.defaultProps = {
    setRegionsId: (e) => undefined,
};

export default RegionSelector;
