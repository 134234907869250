//Components
import { Table } from "antd";
import { Fragment } from "react";
import { useTranslation } from 'react-i18next';

const StatisticsTable = (props) => {

  const { t } = useTranslation();

  console.log('props.data', props.data)
  console.log('props.data', props.data)

  const COLUMNS = [
    {
      title: t("region"),
      dataIndex: "region_long",
      sorter: (a, b) => (a.region_long < b.region_long ? -1 : 1),
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) =>
        Date(a.date) < Date(b.date) ? -1 : 1,
      render: (value) => 
      <Fragment> 
        { new Date(value).getDate() + "-" 
        + (new Date(value).getMonth()+1) + "-"
        + new Date(value).getFullYear() } 
      </Fragment>,
    },
    {
      title: t("max-flow"),
      dataIndex: "max_value",
      sorter: (a, b) => a.max_value - b.max_value,
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) =>
        Date(a.date) < Date(b.date) ? -1 : 1,
      render: (value) => 
      <Fragment> 
        { new Date(value).getDate() + "-" 
        + (new Date(value).getMonth()+1) + "-"
        + new Date(value).getFullYear() } 
      </Fragment>,
    },
    {
      title: t("min-flow"),
      dataIndex: "min_value",
      sorter: (a, b) => a.min_value - b.min_value,
    },
    {
      title: t("avg-flow"),
      dataIndex: "mean_value",
      sorter: (a, b) => a.mean_value - b.mean_value,
    },
    {
      title: t("total-flow"),
      dataIndex: "total_value",
      sorter: (a, b) => a.total_value - b.total_value,
    },
  ];

  const date_format = {
      year: "numeric",
      month:"numeric"
  }

  const DUMMY_DATA = [
    {
      key: "1",
      region: "Region 1",
      max_flux: 4500,
      min_flux: 0,
      mean_flux: 2000,
      total_flux: 30000
    },
    {
      key: "2",
      region: "Region 2",
      max_flux: 1800,
      min_flux: 0,
      mean_flux: 1000,
      total_flux: 10000
    },
    {
      key: "3",
      region: "Region 3",
      max_flux: 600,
      min_flux: 0,
      mean_flux: 200,
      total_flux: 7000
    },
    {
      key: "4",
      region: "Region 4",
      max_flux: 100,
      min_flux: 0,
      mean_flux: 50,
      total_flux: 3000
    },
  ];

  return (
      <Table
        columns={COLUMNS}
        scroll={{ x: 800 }}
        dataSource={props.data? props.data : null }
        key={props.data? props.data.id : null}
        style={{ width: "100%" }}
      ></Table>
  );
};

export default StatisticsTable