import React from "react"
import { Layout, Image, Divider, Button } from "antd";
import LanguageSelector from "./LanguageSelector";
import cepal_logo from "../../assets/CEPAL_logo.png";
import "./CustomHeader.css";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Header } = Layout;

const PublicHeader = (props) => {

  const { t } = useTranslation();
  
  let location = useLocation();
  const [isLogin, setIsLocation] = React.useState(false)
  
  React.useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/restaurar-contrasena') {
      setIsLocation(true);
    }
  }, [location]);
  
  return (
    <Header className={"header_style"}>
      <div className="logo">
        <a
          href="https://www.cepal.org/es"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={cepal_logo} preview={false} />
        </a>
      </div>
      {!isLogin &&  <div style={{ float: "right" }}>
      <Divider type="vertical" style={{ height: "2em" }} />
        <Button
          type="text"
        >
          <a href="login" style={{ color: "black" }}>
            {t('login')}
          </a>
        </Button>
      </div>}
      
      <div style={{ float: "right" }}>
        <LanguageSelector />
      </div>
    </Header>
  );
};

export default PublicHeader;
