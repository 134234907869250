import { Card, Row, Col, Button, Form, Input } from "antd";
//Import Antd Components
import { FaEnvelope } from "react-icons/fa";
import { useTranslation } from 'react-i18next';


const RecoveryForm = (props) => {
  const { t } = useTranslation();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  return (
    <Form
      name="recovery_form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        style={{ marginBottom: "10px" }}
        name="Email"
        rules={[
          {
            required: true,
            message: t('error-msg.no-email'),
          },
        ]}
      >
        <Input
          placeholder={t('email')}
          addonBefore={<FaEnvelope />}
        />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col span={10}>
            <Button
              type="primary"
              className="form_button"
              style={{ borderRadius: "10px 0 0 10px" }}
            >
              <a href="login">{t('cancel')}</a>
            </Button>
          </Col>

          <Col span={14}>
            <Button
              type="primary"
              htmlType="submit"
              className="form_button"
              style={{ borderRadius: "0 10px 10px 0" }}
            >
            {t('gen-password')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default RecoveryForm;
