import { Card } from "antd";
import CardTitle from "../../components/UI/CardTitle";
import { RiMapPinAddFill } from "react-icons/ri";
import CreateRegionForm from "../../components/Forms/CreateRegionForm";
import { useTranslation } from 'react-i18next';

    
const CrearRegion = () => {

  const { t } = useTranslation();
  
  return (
    <Card
      title={<CardTitle title={t("create-region")} icon={RiMapPinAddFill} />}
      style={{ margin: "1rem" }}
    >
        <CreateRegionForm/>
    </Card>
  );
};

export default CrearRegion;
