import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import PublicMap from "../components/Maps/PublicMap.jsx";
import RegionSelector from "../components/RegionSelector/RegionSelector";
import AuthContext from "../store/auth-context.js";


const MapaPublico = (props) => {

  const [selectedDate, setSelectedDate] = useState(undefined) 
  const [selectedRegion, setSelectedRegion] = useState("worldmap")
  const [regionId, setRegionId] = useState(undefined)

  const { user, CheckLogIn, admin } = useContext(AuthContext)
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const Check = async () => {
    await CheckLogIn();
  };

  useEffect(() => {
    Check();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px 20px 10px 20px",
          margin: "5px",
        }}
      >
        <RegionSelector
          mode="single"
          picker="month"
          layout="horizontal"
          setRange={setSelectedDate}
          setRegions={setSelectedRegion}
          setRegionsId={setRegionId}
          searchPosition="right"
          user={user}
          admin={admin}
        />
      </div>
      <PublicMap region={selectedRegion} regionId={regionId} date={selectedDate}/>
    </>
  );
};

export default MapaPublico;
