import { Card } from "antd";
import RecoveryForm from "../Forms/RecoveryForm";
//Images
import cepal_logo from "../../assets/cepal_full.png"

const RecoveryCard = () => {
  return (
    <Card className="login_container">
      <img src={cepal_logo} style={{width:"auto", height:"10em", marginBottom:"2em"}}/>
      <RecoveryForm />
    </Card>
  );
};

export default RecoveryCard;
