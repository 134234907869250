import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import CustomSider from "../components/CustomSider/CustomSider";
import ProtectedView from "../components/UI/ProtectedView";
import DashboardHeader from "../components/CustomHeader/DashboardHeader";

const { Content } = Layout;

const DashboardLayout = (props) => {
  return (
    <>
      
        <Layout
          style={{
            minHeight: "100vh",
          }}
          className="site-layout"
        >
          <ProtectedView>
            <DashboardHeader/>
            <Layout hasSider>
              <CustomSider routes={props.routes} layout={"dashboard"} />
              <Content>
                <div
                  style={{
                    minHeight: 360,
                  }}
                >
                  <Outlet />
                </div>
              </Content>
            </Layout>
          </ProtectedView>
        </Layout>
    </>
  );
};

export default DashboardLayout;
