import React, { useContext, useState, useEffect } from "react";
import { Table } from "antd";
import { FaFilePdf, FaFileImage } from "react-icons/fa";
import config from "../../configs/config";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

const convertGeoserverIdToDatabaseId = (geoserverId) => {
    console.log(geoserverId);
    const s = geoserverId;
    const newId = [s.slice(0, 4), s.slice(5, 31), s.slice(32, 38)].join("");
    return newId;
};

const ReportTable = (props) => {
    const generateReports = async (id) => {
        console.log("the id", id);
        await fetch(config.BACKEND_URL + "report/get-report", {
            method: "POST",
            mode: "cors",
            responseType: "blob",
            credentials: "include",
            headers: {
                "Content-Type": "application/pdf",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
            },
            body: JSON.stringify({
                report_id: id,
            }),
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `FileName.pdf`);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    };

    const generateReport2 = (id) =>{
      console.log("downloading");
      const link = document.createElement("a");
      link.href = config.BACKEND_URL + "report/get-report-uri?id="+id.replaceAll("-","$");
      //link.download = "input-" + date.toISOString() + ".json";
      link.download = `report.pdf`;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    

    const fetchReports = async (dateRange, region) => {
        console.log("fetch",dateRange[0]._d,dateRange[dateRange.length-1]._d);
        await fetch(config.BACKEND_URL + "maps/stats/get-by-id-and-date", {
            method: "POST",
            mode: "cors",

            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin, X-Requested-With, Content-Type, Accept",
            },
            body: JSON.stringify({
                regions: [convertGeoserverIdToDatabaseId(region)],
                initialDate: dateRange[0]._d,
                endingDate: dateRange[dateRange.length-1]._d,
                gas: 1,
            }),
        })
            .then((response) => {
                console.log(response);
                if (response.ok) {
                    // setLoggedIn(true);

                    return response.json();
                } else {
                    return false;
                }
            })
            .then((data) => {
                console.log(data);
                setRegiones(data.payload);
            });
    };
    const [regiones, setRegiones] = useState([]);

    const { t } = useTranslation();
    useEffect(() => {
        const getReports = async () => {
            const data = await fetchReports(props.dateRange, props.region);
            console.log("data", data);
        };
        if (props.dateRange != undefined) {
            getReports();
        }
    }, [props.dateRange, props.region]);

    const COLUMNS = [
        {
            title: t("region"),
            dataIndex: "region",
            sorter: (a, b) => (a.region < b.region ? -1 : 1),
        },
        {
            title: t("date"),
            dataIndex: "date",
            sorter: (a, b) => (Date(a.date) < Date(b.date) ? -1 : 1),
        },
        {
            title: t("download"),
            dataIndex: "download",
            key: "download",
            render: (_, record) => {
                return (
                    <React.Fragment>
                        <a
                            onClick={() => {
                                console.log("PDF");
                                console.log(record.download);
                                //generateReports(record.download);
                                generateReport2(record.download);
                            }}
                        >
                            {<FaFilePdf />}
                            {"PDF"}
                        </a>{" "}
                        {/* <a
              onClick={() => {
                console.log("GeoTIFF");
                console.log(record);

              }}
            >
              {<FaFileImage />}
              {"GeoTIFF"}
            </a> */}
                    </React.Fragment>
                );
            },
        },
    ];

    const date_format = {
        year: "numeric",
        month: "numeric",
    };

    const dateRange = props.dateRange;
    console.log(dateRange);

    const region = props.region;
    if (!dateRange && !region) {
        return <Table columns={COLUMNS}></Table>;
    }
    const date_start = dateRange[0];
    const date_end = dateRange[1];
    const dummy_data = [];
    let loop = new Date(date_start);
    console.log(loop);
    while (loop <= date_end) {
        dummy_data.push(loop);
        let newDate = loop.setMonth(loop.getMonth() + 1);
        loop = new Date(newDate);
    }
    const DUMMY_DATA = dummy_data.map((e, key) => {
        return {
            key: key,
            region: region,
            date: new Date(e).toLocaleString([], date_format),
            download: "",
        };
    });

    const NOT_DUMMY_DATA = regiones.map((region) => {
        let datee = new Date(region.date)
        datee.setDate(datee.getDate()-3);
        datee.setMonth(datee.getMonth() + 1);
        return {
            key: region.id,
            region: region.region_short,
            date: datee.toLocaleString([], date_format),
            download: region.id,
        };
    });
    return <Table columns={COLUMNS} dataSource={NOT_DUMMY_DATA}></Table>;
};

export default ReportTable;
